import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ApiGetSnapshots,
  ApiRemoveCustomerFromSnapshot,
  ISnapshot,
  ISnapshots,
  ResponseGetSnapshotsItem,
  SendMailsForSnapshotIdTemplateData,
  UpsertCustomerSnapshotRequest,
} from './types';

const initialState: ISnapshots = {
  snapshots: [],
  snapshot: null,
  error: '',
  loading: false,
  actionLoading: false,
};

const slice = createSlice({
  name: 'snapshots',
  initialState,
  reducers: {
    // all
    getSnapshotsRequest: (state, i: PayloadAction<ApiGetSnapshots>) => {
      state.error = '';
      state.loading = true;
    },
    getSnapshotsSuccess: (
      state,
      { payload }: PayloadAction<ResponseGetSnapshotsItem[]>,
    ) => {
      state.loading = false;
      state.snapshots = payload;
    },
    getSnapshotsFailure: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    // one
    getSnapshotRequest: (state, i: PayloadAction<number>) => {
      state.error = '';
      state.loading = true;
    },
    getSnapshotSuccess: (
      state,
      { payload }: PayloadAction<ISnapshot | null>,
    ) => {
      state.loading = false;
      state.snapshot = payload;
    },
    getSnapshotFailure: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    // create
    createSnapshotRequest: (
      state,
      i: PayloadAction<UpsertCustomerSnapshotRequest>,
    ) => {
      state.error = '';
      state.loading = true;
    },
    createSnapshotSuccess: (
      state,
      { payload }: PayloadAction<ISnapshot | null>,
    ) => {
      state.loading = false;
      state.snapshot = payload;
    },
    createSnapshotFailure: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    // delete
    deleteSnapshotRequest: (state, i: PayloadAction<number>) => {
      state.error = '';
      state.loading = true;
    },
    deleteSnapshotSuccess: (state) => {
      state.loading = false;
      state.snapshot = null;
    },
    deleteSnapshotFailure: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    // send email
    sendEmailRequest: (
      state,
      i: PayloadAction<{
        id: number;
        templateData: SendMailsForSnapshotIdTemplateData;
      }>,
    ) => {
      state.error = '';
      state.loading = true;
    },
    sendEmailSuccess: (state) => {
      state.loading = false;
    },
    sendEmailFailure: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    // set discord roles
    setDiscordRolesRequest: (
      state,
      i: PayloadAction<{
        id: number;
        roleIds: string[];
      }>,
    ) => {
      state.error = '';
      state.loading = true;
    },
    setDiscordRolesSuccess: (state) => {
      state.loading = false;
    },
    setDiscordRolesFailure: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    // send drop (airdrop)
    // @deprecated
    sendDropRequest: (
      state,
      i: PayloadAction<{
        snapshotId: number;
        dropId: number;
        sendToOwners: boolean;
      }>,
    ) => {
      state.error = '';
      state.actionLoading = true;
    },
    sendDropSuccess: (state) => {
      state.actionLoading = false;
    },
    sendDropFailure: (state, { payload }: PayloadAction<string>) => {
      state.actionLoading = false;
      state.error = payload;
    },
    // send nfts (airdrop)
    sendNftsRequest: (
      state,
      i: PayloadAction<{
        snapshotId: number;
        nftIds: number[];
        sendToOwners: boolean;
      }>,
    ) => {
      state.error = '';
      state.actionLoading = true;
    },
    sendNftsSuccess: (state) => {
      state.actionLoading = false;
    },
    sendNftsFailure: (state, { payload }: PayloadAction<string>) => {
      state.actionLoading = false;
      state.error = payload;
    },
    // refresh snapshot
    refreshSnapshotRequest: (state, i: PayloadAction<number>) => {
      state.error = '';
      state.loading = true;
    },
    refreshSnapshotSuccess: (state) => {
      state.loading = false;
    },
    refreshSnapshotFailure: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    // export snapshot
    exportSnapshotToCsvRequest: (state, i: PayloadAction<number>) => {
      state.error = '';
    },
    exportSnapshotToCsvSuccess: (state, { payload }: PayloadAction) => {
      state.error = '';
    },
    exportSnapshotToCsvFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    // remove customer
    removeCustomerFromSnapshotRequest: (
      state,
      { payload }: PayloadAction<ApiRemoveCustomerFromSnapshot>,
    ) => state,
  },
});

export const {
  getSnapshotsRequest,
  getSnapshotsSuccess,
  getSnapshotsFailure,
  getSnapshotRequest,
  getSnapshotSuccess,
  getSnapshotFailure,
  createSnapshotRequest,
  createSnapshotSuccess,
  createSnapshotFailure,
  deleteSnapshotRequest,
  deleteSnapshotSuccess,
  deleteSnapshotFailure,
  sendEmailRequest,
  sendEmailSuccess,
  sendEmailFailure,
  setDiscordRolesRequest,
  setDiscordRolesSuccess,
  setDiscordRolesFailure,
  sendDropRequest,
  sendDropSuccess,
  sendDropFailure,
  sendNftsRequest,
  sendNftsSuccess,
  sendNftsFailure,
  refreshSnapshotRequest,
  refreshSnapshotSuccess,
  refreshSnapshotFailure,
  removeCustomerFromSnapshotRequest,
  exportSnapshotToCsvRequest,
  exportSnapshotToCsvSuccess,
  exportSnapshotToCsvFailure,
} = slice.actions;
export const { reducer } = slice;
