import React, { useEffect, useRef } from 'react';
import SimpleBar from 'simplebar-react';
import SideBarContent from './SidebarContent';
import { LeftSidebarProps } from './types';

const LeftSidebar = ({ hideUserProfile }: LeftSidebarProps) => {
  const menuNodeRef = useRef<HTMLDivElement>(null);

  /**
   * Handle the click anywhere in doc
   */
  const handleOtherClick = (e: MouseEvent) => {
    if (
      menuNodeRef &&
      menuNodeRef.current &&
      menuNodeRef.current.contains(e.target as HTMLElement)
    )
      return;
    // else hide the menubar
    if (document.body) {
      document.body.classList.remove('sidebar-enable');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOtherClick, false);

    return () => {
      document.removeEventListener('mousedown', handleOtherClick, false);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="leftside-menu" ref={menuNodeRef}>
        <SimpleBar
          style={{ maxHeight: '100%' }}
          timeout={500}
          scrollbarMaxSize={320}
        >
          <SideBarContent hideUserProfile={hideUserProfile} />
        </SimpleBar>
      </div>
    </React.Fragment>
  );
};

export default LeftSidebar;
