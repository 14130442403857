import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import { HyperDatepickerProps } from './types';
import CustomTimeInput from './CustomTimeInput';
import styles from './styles.module.scss';
import { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';

const HyperDatepicker = forwardRef<ReactDatePicker, HyperDatepickerProps>(
  (props, ref) => {
    const handleChangeTime = (time: string) => {
      const [hh, mm, ss] = time.split(':');
      const targetDate = props.value || new Date();
      targetDate.setHours(Number(hh) || 0, Number(mm) || 0, Number(ss) || 0);
      props.onChange(targetDate);
    };
    return (
      <div>
        <div className={`input-group ${Boolean(props.error) && styles.error}`}>
          <DatePicker
            placeholderText={props.placeholder}
            ref={ref}
            timeIntervals={props.tI}
            className={classNames(
              'form-control form-control-light',
              props.inputClass,
            )}
            selected={props.value}
            onChange={(date) => {
              if (date) {
                props.onChange(date as Date);
              }
            }}
            showTimeSelect={props.showTimeSelect}
            timeFormat="HH:mm:ss"
            timeCaption={props.timeCaption}
            dateFormat={props.dateFormat || 'dd/MM/yyyy H:mm:ss'}
            minDate={props.minDate}
            maxDate={props.maxDate}
            monthsShown={props.monthsShown}
            showTimeSelectOnly={props.showTimeSelectOnly}
            inline={props.inline}
            autoComplete="off"
            showTimeInput={props.showTimeInput}
            timeInputLabel="Time:"
            customTimeInput={
              <CustomTimeInput onChangeCustom={handleChangeTime} />
            }
          />
          <div className="input-group-append">
            <span className="input-group-text bg-primary border-primary text-white">
              <i className="mdi mdi-calendar-range font-13"></i>
            </span>
          </div>
        </div>
        {Boolean(props.error) && (
          <span className={`${styles.errormsg} invalid-feedback`}>
            {props.error}
          </span>
        )}
      </div>
    );
  },
);

export default HyperDatepicker;
