/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateNewstickerRequest,
  INewstickersState,
  Newsticker,
  UpdateNewstickerRequest,
} from './types';

const initialState: INewstickersState = {
  list: [],
  current: null,
  error: '',
  loading: false,
};

const newstickersSlice = createSlice({
  name: 'newstickers',
  initialState,
  reducers: {
    getNewstickersRequest: (state, { payload }: PayloadAction) => {
      state.loading = true;
      state.error = '';
    },
    getNewstickersSuccess: (
      state,
      { payload }: PayloadAction<(Newsticker & { active: boolean })[]>,
    ) => {
      state.loading = false;
      state.list = payload;
    },
    getNewstickersFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    getNewstickerRequest: (state, { payload }: PayloadAction<number>) => {
      state.loading = true;
      state.error = '';
    },
    getNewstickerSuccess: (
      state,
      { payload }: PayloadAction<Newsticker | null>,
    ) => {
      state.loading = false;
      state.current = payload;
    },
    getNewstickerFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    createNewstickerRequest: (
      state,
      { payload }: PayloadAction<CreateNewstickerRequest>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    createNewstickerSuccess: (
      state,
      { payload }: PayloadAction<Newsticker>,
    ) => {
      state.loading = false;
    },
    createNewstickerFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    updateNewstickerRequest: (
      state,
      { payload }: PayloadAction<UpdateNewstickerRequest>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    updateNewstickerSuccess: (
      state,
      { payload }: PayloadAction<Newsticker>,
    ) => {
      state.loading = false;
      state.current = payload;
    },
    updateNewstickerFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    deleteNewstickerRequest: (state, { payload }: PayloadAction<number>) => {
      state.loading = true;
      state.error = '';
    },
    deleteNewstickerSuccess: (state, { payload }: PayloadAction) => {
      state.loading = false;
      state.current = null;
    },
    deleteNewstickerFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const {
  getNewstickersRequest,
  getNewstickersSuccess,
  getNewstickersFailure,
  getNewstickerRequest,
  getNewstickerSuccess,
  getNewstickerFailure,
  createNewstickerRequest,
  createNewstickerSuccess,
  createNewstickerFailure,
  updateNewstickerRequest,
  updateNewstickerSuccess,
  updateNewstickerFailure,
  deleteNewstickerRequest,
  deleteNewstickerSuccess,
  deleteNewstickerFailure,
} = newstickersSlice.actions;

export const { reducer } = newstickersSlice;
