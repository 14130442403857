import { Route } from 'react-router-dom';
import { ISecureRouteParams } from './types';
import Default from 'layouts/Default';

const PublicRoute = ({ children, ...rest }: ISecureRouteParams) => {
  return (
    <Route
      {...rest}
      render={() => (
        <>
          <Default>{children}</Default>
        </>
      )}
    />
  );
};

export default PublicRoute;
