/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'redux/auth/types';
import { IUserList, IUserState } from './types';

const initialState: IUserState = {
  users: null,
  user: null,
  error: null,
  loading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUsersRequest: (state) => {
      state.loading = true;
      state.error = '';
    },
    getUsersSuccess: (state, { payload }: PayloadAction<IUserList>) => {
      state.loading = false;
      state.users = payload;
    },
    getUsersFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    getUserRequest: (state, { payload }: PayloadAction<string>) => {
      state.loading = true;
      state.error = '';
    },
    getUserSuccess: (state, { payload }: PayloadAction<IUser>) => {
      state.loading = false;
      state.user = payload;
    },
    getUserFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const {
  getUsersRequest,
  getUsersSuccess,
  getUsersFailure,
  getUserRequest,
  getUserSuccess,
  getUserFailure,
} = userSlice.actions;

export const { reducer } = userSlice;
