import { Link } from 'react-router-dom';
import { IMenuItemLinkProps } from './types';
import classNames from 'classnames';

const MenuItemLink = ({ item, className }: IMenuItemLinkProps) => {
  return (
    <Link
      to={String(item.url)}
      target={item.target}
      className={classNames(
        'side-nav-link-ref',
        'side-sub-nav-link',
        'ps-3',
        'pe-2',
        className,
      )}
      data-menu-key={item.key}
    >
      {item.icon && <i className={item.icon}></i>}
      {item.badge && (
        <span
          className={`badge badge-${item.badge.variant} rounded-pill font-10 float-end`}
        >
          {item.badge.text}
        </span>
      )}
      <span> {item.label} </span>
    </Link>
  );
};

export default MenuItemLink;
