import * as React from 'react';
import { ICustomTimeInputProps } from './types';
import styles from './styles.module.scss';

const CustomTimeInput = ({ date, onChangeCustom }: ICustomTimeInputProps) => {
  const value = date instanceof Date ? date.toLocaleTimeString() : '';

  return (
    <input
      type="time"
      step="1"
      value={value}
      onChange={(e) => onChangeCustom(e.target.value)}
      className={styles.timeInput}
    />
  );
};

export default CustomTimeInput;
