import React from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { SortableItem } from './SortableItem';
import { SortableProps } from './types';
import { Button } from 'react-bootstrap';

export default function Sortable<T>({
  items,
  setItems,
  handleRemove,
  renderItem,
}: SortableProps<T>) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over !== null && active.id !== over.id) {
      setItems((it: T[]) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(it, oldIndex, newIndex);
      });
    }
  };

  return (
    <div className={'mb-2'}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((id: string) => (
            <div className={'d-flex align-items-center'} key={id}>
              <SortableItem
                id={id}
                handleRemove={handleRemove}
                renderItem={renderItem}
              />
              {Boolean(handleRemove) && (
                <Button
                  variant="link"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (handleRemove) {
                      handleRemove(id);
                    }
                  }}
                >
                  <i className="mdi mdi-delete-outline text-danger"></i>
                </Button>
              )}
            </div>
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
}
