/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilesType, IStorageState } from './types';

const initialState: IStorageState = {
  files: {},
  error: null,
  loading: false,
};

const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    uploadFilesRequest: (
      state,
      {
        payload,
      }: PayloadAction<{
        path: string;
        files: Record<string, File>;
        cb?: (fileUrls: FilesType) => void;
      }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    uploadFilesSuccess: (
      state,
      { payload }: PayloadAction<FilesType | null>,
    ) => {
      state.loading = false;
      state.files = payload;
    },
    uploadFilesFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const { uploadFilesRequest, uploadFilesSuccess, uploadFilesFailure } =
  storageSlice.actions;

export const { reducer } = storageSlice;
