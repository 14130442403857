import { Modal } from 'react-bootstrap';
import { IWarningPopupProps } from './types';

const WarningPopup = ({
  show,
  toggle,
  title,
  desc,
  onApply,
  okButtonText = 'OK',
}: IWarningPopupProps) => {
  return (
    <Modal show={show} onHide={toggle} size="sm">
      <Modal.Body className="p-4">
        <div className="text-center">
          <i className="dripicons-warning h1 text-warning"></i>
          <h4 className="mt-2">{title}</h4>
          <p className="mt-3">{desc}</p>

          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-secondary my-2"
              data-bs-dismiss="modal"
              onClick={toggle}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-warning my-2"
              data-bs-dismiss="modal"
              onClick={onApply}
            >
              {okButtonText}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WarningPopup;
