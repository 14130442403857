import { call, put } from 'redux-saga/effects';
import { generateErrorMessage } from 'utils';
import api from 'api/middleware';
import config from 'api/config';
import {
  createNewstickerFailure,
  createNewstickerSuccess,
  deleteNewstickerFailure,
  deleteNewstickerSuccess,
  getNewstickerFailure,
  getNewstickersFailure,
  getNewstickersRequest,
  getNewstickersSuccess,
  getNewstickerSuccess,
  updateNewstickerFailure,
  updateNewstickerSuccess,
} from './reducer';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { toastSettings } from 'constants/global';
import { CreateNewstickerRequest, UpdateNewstickerRequest } from './types';
import { history } from 'navigation';
import paths from 'navigation/paths';

const NEWSTICKER_CLOUD_PATH = 'newstickers/bg-images';

export function* getNewstickersSaga() {
  try {
    const { data } = yield call(api, config.newstickers);
    yield put(getNewstickersSuccess(data));
  } catch (e) {
    yield put(getNewstickersFailure(generateErrorMessage(e)));
  }
}

export function* getNewstickerSaga({ payload: id }: PayloadAction<number>) {
  try {
    const { data } = yield call(api, config.newstickerById(id));
    yield put(getNewstickerSuccess(data));
  } catch (e) {
    yield put(getNewstickerFailure(generateErrorMessage(e)));
  }
}

export function* createNewstickerSaga({
  payload,
}: PayloadAction<CreateNewstickerRequest>) {
  try {
    const { bgImageFile, ...body } = payload;

    let bgImage;
    if (bgImageFile) {
      const fd = new FormData();
      fd.append('folderPath', NEWSTICKER_CLOUD_PATH);
      fd.append('files', bgImageFile);
      const { data } = yield call(api.post, config.files, fd);
      bgImage = data.urls[0];
    }

    const { data } = yield call(api.post, config.newstickers, {
      ...body,
      bgImage,
    });
    history.push(paths.newstickers);
    toast.success(`Newsticker has been created!`, toastSettings);
    yield put(createNewstickerSuccess(data));
  } catch (e) {
    yield put(createNewstickerFailure(generateErrorMessage(e)));
  }
}

export function* updateNewstickerSaga({
  payload,
}: PayloadAction<UpdateNewstickerRequest>) {
  try {
    const { id, bgImageFile, ...body } = payload;

    let newUploadedFileUrl;
    if (bgImageFile) {
      const fd = new FormData();
      fd.append('folderPath', NEWSTICKER_CLOUD_PATH);
      fd.append('files', bgImageFile);
      const { data } = yield call(api.post, config.files, fd);
      newUploadedFileUrl = data.urls[0];
    }

    const { data } = yield call(api.patch, config.newstickerById(id), {
      ...body,
      bgImage: newUploadedFileUrl || body.bgImage,
    });

    history.push(paths.newstickers);
    toast.success(`Newsticker ${id} has been updated!`, toastSettings);
    yield put(updateNewstickerSuccess(data));
  } catch (e) {
    yield put(updateNewstickerFailure(generateErrorMessage(e)));
  }
}

export function* deleteNewstickerSaga({ payload: id }: PayloadAction<number>) {
  try {
    yield call(api.delete, config.newstickerById(id));
    history.replace(paths.newstickers);
    toast.success(`Newsticker ${id} has been deleted!`, toastSettings);
    yield put(deleteNewstickerSuccess());
  } catch (e) {
    yield put(deleteNewstickerFailure(generateErrorMessage(e)));
  }
}
