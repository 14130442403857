/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthState, IUser } from './types';

const initialState: IAuthState = {
  user: null,
  loading: false,
  error: null,
  userLoggedIn: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signInRequest: (
      state,
      { payload }: PayloadAction<{ email: string; password: string }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    signInSuccess: (state) => {
      state.loading = false;
      state.userLoggedIn = true;
    },
    signInFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    logoutUser: (state) => {
      state.userLoggedIn = false;
    },
    meRequest: (state) => {
      state.loading = true;
      state.error = '';
    },
    meSuccess: (state, { payload }: PayloadAction<IUser>) => {
      state.user = payload;
      state.loading = false;
    },
    meFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const {
  signInRequest,
  signInSuccess,
  signInFailure,
  meRequest,
  meSuccess,
  meFailure,
  logoutUser,
} = authSlice.actions;

export const { reducer } = authSlice;
