import ProtectedLayout from 'layouts/ProtectedLayout';
import { Redirect, Route } from 'react-router-dom';
import { ISecureRouteParams } from './types';
import routes from './paths';
import { getToken, getTokenKeycloak } from 'api/helpers';
import config from 'api/config';

const PrivateRoute = ({ children, ...rest }: ISecureRouteParams) => {
  const token = config.env.useKeycloak ? getTokenKeycloak() : getToken();
  const isAuth = config.env.useKeycloak ? token?.access_token : token?.token;

  return (
    <Route
      {...rest}
      render={() =>
        isAuth ? (
          <ProtectedLayout>{children}</ProtectedLayout>
        ) : (
          <Redirect to={routes.login} />
        )
      }
    />
  );
};

export default PrivateRoute;
