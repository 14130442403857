import { call, put } from 'redux-saga/effects';
import { ApiRemoveCustomerFromSnapshot } from './types';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  createSnapshotFailure,
  createSnapshotSuccess,
  deleteSnapshotFailure,
  deleteSnapshotSuccess,
  getSnapshotFailure,
  getSnapshotRequest,
  getSnapshotsFailure,
  getSnapshotsSuccess,
  getSnapshotSuccess,
  refreshSnapshotFailure,
  refreshSnapshotSuccess,
  sendEmailFailure,
  sendEmailSuccess,
  sendNftsFailure,
  sendNftsSuccess,
  sendDropSuccess,
  sendDropFailure,
  setDiscordRolesFailure,
  setDiscordRolesSuccess,
  exportSnapshotToCsvFailure,
  exportSnapshotToCsvSuccess,
} from './reducer';
import { history } from '../../navigation';
import { generatePath } from 'react-router';
import routes from '../../navigation/paths';
import {
  SendMailsForSnapshotIdTemplateData,
  UpsertCustomerSnapshotRequest,
} from './types';
import { toast } from 'react-toastify';
import { toastSettings } from 'constants/global';
import { INFTsData } from '../nft/types';
import { downloadCsv, generateErrorMessage } from 'utils';
import api from 'api/middleware';
import config from 'api/config';

export function* getSnapshots() {
  try {
    const { data } = yield call(api, config.customerSnapshots);

    yield put(getSnapshotsSuccess(data));
  } catch (e) {
    yield put(getSnapshotsFailure(generateErrorMessage(e)));
  }
}

export function* getSnapshot({ payload }: PayloadAction<number>) {
  try {
    const { data } = yield call(
      api,
      config.customerSnapshotById(payload.toString()),
    );
    yield put(getSnapshotSuccess(data));
  } catch (e) {
    yield put(getSnapshotFailure(generateErrorMessage(e)));
  }
}

export function* createSnapshot({
  payload,
}: PayloadAction<UpsertCustomerSnapshotRequest>) {
  try {
    const { data } = yield call(api.post, config.customerSnapshots, payload);
    yield put(createSnapshotSuccess(data));
    history.push(
      generatePath(routes.customersSnapshot, { snapshotId: data.id }),
    );
  } catch (e) {
    yield put(createSnapshotFailure(generateErrorMessage(e)));
  }
}

export function* deleteSnapshot({ payload }: PayloadAction<number>) {
  try {
    yield call(api.delete, config.customerSnapshotById(payload.toString()));
    yield put(deleteSnapshotSuccess());
    history.push(generatePath(routes.customersSnapshots));
  } catch (e) {
    yield put(deleteSnapshotFailure(generateErrorMessage(e)));
  }
}

export function* refreshSnapshot({ payload }: PayloadAction<number>) {
  try {
    yield call(api.post, config.customerSnapshotRefresh(payload.toString()));
    yield put(refreshSnapshotSuccess());
    yield put(getSnapshotRequest(payload));
  } catch (e) {
    yield put(refreshSnapshotFailure(generateErrorMessage(e)));
  }
}

export function* sendEmail({
  payload,
}: PayloadAction<{
  id: number;
  templateData: SendMailsForSnapshotIdTemplateData;
}>) {
  try {
    yield call(
      api.post,
      config.customerSnapshotSendMail(payload.id.toString()),
      payload.templateData,
    );
    yield put(sendEmailSuccess());
    yield toast.success(
      'E-mail has been sent to all customers successfully!',
      toastSettings,
    );
  } catch (e) {
    yield put(sendEmailFailure(generateErrorMessage(e)));
  }
}

export function* setDiscordRoles({
  payload,
}: PayloadAction<{ id: number; roleIds: string[] }>) {
  try {
    const {
      data: {
        updatedUsersInDb,
        updatedConnectedUsersInDb,
        updatedUsersInDiscord,
      },
    } = yield call(
      api.post,
      config.customerSnapshotSetRole(payload.id.toString()),
      { roleIds: payload.roleIds },
    );
    yield put(setDiscordRolesSuccess());
    yield toast.success(
      `Upd users: ${updatedUsersInDb}, 
      upd users with acc: ${updatedConnectedUsersInDb}, 
      upd users in Discord: ${updatedUsersInDiscord}`,
      toastSettings,
    );
  } catch (e) {
    yield put(setDiscordRolesFailure(generateErrorMessage(e)));
  }
}

export function* sendDrop({
  payload: { snapshotId, dropId, sendToOwners },
}: PayloadAction<{
  snapshotId: number;
  dropId: number;
  sendToOwners: boolean;
}>) {
  try {
    const { data }: { data: INFTsData } = yield call(api, config.nfts, {
      params: { dropId: String(dropId), limit: 100, offset: 0 },
    });

    const nftIds = data.list.map((nft) => nft.id);

    if (!nftIds.length) {
      yield put(sendDropFailure('No NFT in this drop'));
      yield toast.error('No NFT in this drop! ', toastSettings);
      return;
    }

    const { data: sendNftsResponse } = yield call(
      api.post,
      config.customerSnapshotSendNft(String(snapshotId)),
      { nftIds, sendToOwners },
    );

    if (sendNftsResponse.count.nfts === 0) {
      yield put(sendDropFailure('No one NFTs has been sent'));
      yield toast.error('No one NFTs has been sent! ', toastSettings);
    } else {
      yield put(sendDropSuccess());
      yield toast.success(
        `${sendNftsResponse.status}! ${sendNftsResponse.count.customers} processed customer(s), 
        ${sendNftsResponse.count.nfts} NFTs sent to relayer`,
        toastSettings,
      );
    }
  } catch (e) {
    yield put(sendDropFailure(generateErrorMessage(e)));
  }
}

export function* sendNfts({
  payload: { snapshotId, nftIds, sendToOwners },
}: PayloadAction<{
  snapshotId: number;
  nftIds: number[];
  sendToOwners: boolean;
}>) {
  try {
    const { data: sendNftsResponse } = yield call(
      api.post,
      config.customerSnapshotSendNft(String(snapshotId)),
      { nftIds, sendToOwners },
    );

    if (sendNftsResponse.count.nfts === 0) {
      yield put(sendNftsFailure('No one NFTs has been sent'));
    } else {
      yield put(sendNftsSuccess());
      yield toast.success(
        `${sendNftsResponse.status}! ${sendNftsResponse.count.customers} processed customer(s), 
        ${sendNftsResponse.count.nfts} NFTs sent to relayer`,
        toastSettings,
      );
    }
  } catch (e) {
    yield put(sendNftsFailure(generateErrorMessage(e)));
  }
}

export function* removeCustomersFromSnapshot({
  payload,
}: PayloadAction<ApiRemoveCustomerFromSnapshot>) {
  try {
    yield call(api.delete, config.customerSnapshot(String(payload.id)), {
      data: { customerIds: payload.customerIds },
    });
    yield put(getSnapshotRequest(payload.id));
  } catch (e) {
    yield put(getSnapshotFailure(generateErrorMessage(e)));
  }
}

export function* exportSnapshotToCsv({ payload: id }: PayloadAction<number>) {
  try {
    const { data } = yield call(api, config.exportCustomerSnapshot(id, 'csv'));
    downloadCsv(data, `customer-snapshot-${id}`);

    yield put(exportSnapshotToCsvSuccess());
  } catch (e) {
    yield put(exportSnapshotToCsvFailure(generateErrorMessage(e)));
  }
}
