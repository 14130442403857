import { IPasswordInput } from './types';
import { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';

const PasswordInput = <T extends string>({
  name,
  placeholder,
  errors,
  register,
  className,
}: IPasswordInput<T>) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <InputGroup className="mb-0">
        <Form.Control
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          name={name}
          id={name}
          as="input"
          className={className}
          isInvalid={errors && errors[name] ? true : false}
          {...(register ? register(name) : {})}
          autoComplete={name}
        />
        <div
          className={classNames('input-group-text', 'input-group-password', {
            'show-password': showPassword,
          })}
          data-password={showPassword ? 'true' : 'false'}
        >
          <span
            className="password-eye"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          ></span>
        </div>
      </InputGroup>
    </>
  );
};

export default PasswordInput;
