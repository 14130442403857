/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAuction,
  IAuctionState,
  ApiUpdateAuctionRequest,
  AuctionLog,
  CreateAuctionRequest,
} from './types';

const initialState: IAuctionState = {
  auctions: null,
  auction: null,
  auctionLogs: null,
  error: null,
  loading: false,
};

const auctionSlice = createSlice({
  name: 'auction',
  initialState,
  reducers: {
    createAuctionRequest: (
      state,
      { payload }: PayloadAction<CreateAuctionRequest>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    createAuctionSuccess: (state) => {
      state.loading = false;
    },
    createAuctionFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    getAuctionRequest: (state, i: PayloadAction<number>) => {
      state.loading = true;
      state.error = '';
    },
    getAuctionSuccess: (state, { payload }: PayloadAction<IAuction | null>) => {
      state.loading = false;
      state.auction = payload;
    },
    getAuctionFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    getAuctionByNftIdRequest: (
      state,
      { payload }: PayloadAction<{ nftId: string }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    getAuctionByNftIdSuccess: (
      state,
      { payload }: PayloadAction<IAuction | null>,
    ) => {
      state.loading = false;
      state.auction = payload;
    },
    getAuctionByNftIdFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    getAuctionsRequest: (
      state,
      {
        payload,
      }: PayloadAction<{
        limit?: number;
        offset?: number;
        filter?: Record<string, string>;
        sort?: string[];
      }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    getAuctionsSuccess: (
      state,
      { payload }: PayloadAction<{ amount: number; list: IAuction[] }>,
    ) => {
      state.auctions = payload;
      state.loading = false;
    },
    getAuctionsFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    deleteAuctionRequest: (state, i: PayloadAction<number>) => {
      state.error = '';
    },
    updateAuctionRequest: (
      state,
      i: PayloadAction<ApiUpdateAuctionRequest>,
    ) => {
      state.loading = true;
    },
    getAuctionLogsRequest: (state, i: PayloadAction<number>) => {
      state.loading = true;
      state.error = '';
    },
    getAuctionLogsSuccess: (
      state,
      { payload }: PayloadAction<AuctionLog[] | null>,
    ) => {
      state.loading = false;
      state.auctionLogs = payload;
    },
    getAuctionLogsFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const {
  createAuctionRequest,
  createAuctionSuccess,
  createAuctionFailure,
  getAuctionRequest,
  getAuctionSuccess,
  getAuctionFailure,
  getAuctionByNftIdRequest,
  getAuctionByNftIdSuccess,
  getAuctionByNftIdFailure,
  getAuctionsRequest,
  getAuctionsSuccess,
  getAuctionsFailure,
  deleteAuctionRequest,
  updateAuctionRequest,
  getAuctionLogsRequest,
  getAuctionLogsSuccess,
  getAuctionLogsFailure,
} = auctionSlice.actions;

export const { reducer } = auctionSlice;
