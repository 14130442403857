import { IDefinitionProps } from './types';

const Definition = ({ term, definition }: IDefinitionProps) => (
  <p className="mb-2">
    <strong className="text-dark min-w-300 d-inline-block">{term}</strong>
    <span className="ms-2 text-body">{definition}</span>
  </p>
);

export default Definition;
