import { call, put } from 'redux-saga/effects';
import { ApiGetCustomers, IAddCustomesToSnapshots } from './types';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  exportAllCustomersFailure,
  exportAllCustomersSuccess,
  getCustomersFailure,
  getCustomersSuccess,
  getCustomerSuccess,
  sendMagicLinkFailure,
  sendMagicLinkSuccess,
} from './reducer';
import { downloadBlob, generateErrorMessage } from 'utils';
import { toast } from 'react-toastify';
import { toastSettings } from 'constants/global';
import api from 'api/middleware';
import config from 'api/config';

export function* getCustomers({ payload }: PayloadAction<ApiGetCustomers>) {
  try {
    const { filter, fetch, ...data } = payload;
    const {
      data: { list, amount, allCustomerIds },
    } = yield call(api, config.customers, { params: { ...data, ...filter } });

    yield put(getCustomersSuccess({ list, amount, allCustomerIds }));
  } catch (e) {
    yield put(getCustomersFailure(generateErrorMessage(e)));
  }
}

export function* getCustomer({
  payload,
}: PayloadAction<{ id: number; join?: string[] }>) {
  try {
    const { data } = yield call(api, config.customer(payload.id.toString()), {
      params: { join: payload.join },
    });
    yield put(getCustomerSuccess(data));
  } catch (e) {
    yield put(getCustomersFailure(generateErrorMessage(e)));
  }
}

export function* exportAllCustomers({
  payload,
}: PayloadAction<ApiGetCustomers>) {
  try {
    const { filter, ...params } = payload;
    const { data } = yield call(api, config.customersExport('csv'), {
      params: { ...params, ...filter },
      responseType: 'blob',
    });
    downloadBlob(data, 'exported-customers.csv');
    yield put(exportAllCustomersSuccess());
  } catch (e) {
    yield put(exportAllCustomersFailure(generateErrorMessage(e)));
  }
}
export function* addCustomerToSnapshot({
  payload,
}: PayloadAction<IAddCustomesToSnapshots>) {
  try {
    yield call(api.put, config.customerSnapshot(payload.id.toString()), {
      customerIds: payload.customerIds,
    });
  } catch (e) {
    yield put(getCustomersFailure(generateErrorMessage(e)));
  }
}

export function* sendMagicLink({
  payload,
}: PayloadAction<{ customerId: number; expiresIn: string }>) {
  try {
    yield call(
      api.post,
      config.customerSendLink(payload.customerId.toString()),
      {
        expiresIn: payload.expiresIn,
      },
    );
    yield put(sendMagicLinkSuccess());
    yield toast.success(
      'Magic link has been sent successfully!',
      toastSettings,
    );
  } catch (e) {
    yield put(
      sendMagicLinkFailure(
        generateErrorMessage(e, ({ details }) => `Couldn't send. ${details}`),
      ),
    );
  }
}
