import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import api from 'api/middleware';
import config from 'api/config';
import { FilesType } from './types';
import { generateErrorMessage } from 'utils';
import { uploadFilesFailure, uploadFilesSuccess } from './reducer';

export function* uploadFilesSaga({
  payload,
}: Partial<
  PayloadAction<{
    path: string;
    files: Record<string, File>;
    cb?: (fileUrls: FilesType) => void;
  }>
>) {
  if (!payload) return [];

  try {
    const fd = new FormData();
    fd.append('folderPath', payload.path);
    fd.append('notDeleteOld', 'true');
    Object.entries(payload.files).forEach(([fileType, file]) => {
      fd.append('files', file);
      fd.append('names[]', fileType);
    });

    const response: { data: { urls: Record<string, string> } } = yield call(
      api.post,
      config.files,
      fd,
    );

    const urls = response.data.urls;

    yield put(uploadFilesSuccess(urls));
    if (payload.cb) payload.cb(urls);

    return response.data.urls;
  } catch (e) {
    yield put(uploadFilesFailure(generateErrorMessage(e)));
    return [];
  }
}
