import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ApiGetCustomers,
  ICustomers,
  ICustomer,
  IAddCustomesToSnapshots,
} from './types';

const initialState: ICustomers = {
  customers: [],
  allIds: [],
  customer: null,
  amount: 0,
  error: '',
  loading: false,
};

const slice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    getCustomersRequest: (
      state,
      { payload }: PayloadAction<ApiGetCustomers>,
    ) => {
      state.loading = payload.fetch || false;
    },
    getCustomersSuccess: (
      state,
      {
        payload: { list, amount, allCustomerIds },
      }: PayloadAction<{
        list: ICustomer[];
        amount: number;
        allCustomerIds: number[];
      }>,
    ) => {
      state.loading = false;
      state.customers = list;
      state.amount = amount;
      state.allIds = allCustomerIds;
    },
    getCustomersFailure: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    getCustomerRequest: (
      state,
      i: PayloadAction<{ id: number; join?: string[] }>,
    ) => {
      state.loading = true;
    },
    getCustomerSuccess: (
      state,
      { payload }: PayloadAction<ICustomer | null>,
    ) => {
      state.loading = false;
      state.customer = payload;
    },

    exportAllCustomersRequest: (state, i: PayloadAction<ApiGetCustomers>) => {
      state.loading = true;
    },
    exportAllCustomersSuccess: (state) => {
      state.loading = false;
    },
    exportAllCustomersFailure: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    addCustomerToSnapshotRequest: (
      state,
      { payload }: PayloadAction<IAddCustomesToSnapshots>,
    ) => state,
    sendMagicLinkRequest: (
      state,
      i: PayloadAction<{ customerId: number; expiresIn: string }>,
    ) => {
      state.loading = true;
    },
    sendMagicLinkSuccess: (state) => {
      state.loading = false;
    },
    sendMagicLinkFailure: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const {
  getCustomersRequest,
  getCustomersSuccess,
  getCustomersFailure,
  getCustomerRequest,
  getCustomerSuccess,
  exportAllCustomersRequest,
  exportAllCustomersSuccess,
  exportAllCustomersFailure,
  addCustomerToSnapshotRequest,
  sendMagicLinkRequest,
  sendMagicLinkSuccess,
  sendMagicLinkFailure,
} = slice.actions;
export const { reducer } = slice;
