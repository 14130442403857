import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  createAuctionFailure,
  createAuctionSuccess,
  getAuctionByNftIdFailure,
  getAuctionByNftIdSuccess,
  getAuctionFailure,
  getAuctionLogsFailure,
  getAuctionLogsSuccess,
  getAuctionsFailure,
  getAuctionsSuccess,
  getAuctionSuccess,
} from './reducer';
import { ApiUpdateAuctionRequest, CreateAuctionRequest } from './types';
import { getNFTByIdRequest } from 'redux/nft/reducer';
import { history } from 'navigation';
import routes from 'navigation/paths';
import { generateErrorMessage } from 'utils';
import api from 'api/middleware';
import config from 'api/config';

export function* createAuctionSaga({
  payload,
}: PayloadAction<CreateAuctionRequest>) {
  try {
    yield call(api.post, config.auction, payload);
    yield put(createAuctionSuccess());
    yield put(getNFTByIdRequest({ nftId: payload.itemId.toString() }));
  } catch (e) {
    yield put(createAuctionFailure(generateErrorMessage(e)));
  }
}

export function* getAuctionByIdSaga({
  payload: { nftId },
}: PayloadAction<{ nftId: string }>) {
  try {
    const { data } = yield call(api, config.auction, {
      params: { itemId: nftId },
    });
    yield put(getAuctionByNftIdSuccess(data.list[0]));
  } catch (e) {
    yield put(getAuctionByNftIdFailure(generateErrorMessage(e)));
  }
}

export function* getAuction({ payload }: PayloadAction<number>) {
  try {
    const { data } = yield call(api, config.auctionById(payload.toString()));
    yield put(getAuctionSuccess(data));
  } catch (e) {
    yield put(getAuctionFailure(generateErrorMessage(e)));
  }
}

export function* getAuctionsSaga({
  payload,
}: PayloadAction<{
  limit?: number;
  offset?: number;
  filter?: Record<string, string>;
  sort?: string[];
}>) {
  try {
    const { data } = yield call(api, config.auction, { params: payload });
    yield put(getAuctionsSuccess(data));
  } catch (e) {
    yield put(getAuctionsFailure(generateErrorMessage(e)));
  }
}

export function* deleteAuction({ payload }: PayloadAction<number>) {
  try {
    yield call(api.delete, config.auctionById(payload.toString()));
    history.push(routes.auctions);
  } catch (e) {
    yield put(getAuctionsFailure(generateErrorMessage(e)));
  }
}

export function* updateAuction({
  payload,
}: PayloadAction<ApiUpdateAuctionRequest>) {
  try {
    const { id, ...body } = payload;

    yield call(api.patch, config.auctionById(id?.toString() as string), body);
  } catch (e) {
    yield put(getAuctionsFailure(generateErrorMessage(e)));
  }
}

export function* getAuctionLogs({ payload }: PayloadAction<number>) {
  try {
    const { data } = yield call(api, config.auctionLogs(payload.toString()));
    yield put(getAuctionLogsSuccess(data));
  } catch (e) {
    yield put(getAuctionLogsFailure(generateErrorMessage(e)));
  }
}
