import { Token, TokenKeycloak } from './types';
import apiAuth from './middlewareAuth';
import config from './config';

export const ADMIN_TOKEN = 'admin_token';

export const ADMIN_TOKEN_KEYCLOAK = 'admin_token_keycloak';

export const getToken = () => {
  const token = localStorage.getItem(ADMIN_TOKEN);
  return token ? JSON.parse(token) : undefined;
};

export const setToken = (token: Token) => {
  localStorage.setItem(ADMIN_TOKEN, JSON.stringify(token));
};

export const refreshToken = async (refreshToken: string) => {
  return await apiAuth
    .post(config.tokenRefresh, {
      refreshToken: refreshToken,
    })
    .then((response) => {
      const token = response.data;
      setToken(token as Token);
      return token;
    });
};

export const getTokenKeycloak = () => {
  const token = localStorage.getItem(ADMIN_TOKEN_KEYCLOAK);
  return token ? JSON.parse(token) : undefined;
};

export const setTokenKeycloak = (token: TokenKeycloak) => {
  localStorage.setItem(ADMIN_TOKEN_KEYCLOAK, JSON.stringify(token));
};

export const refreshTokenKeycloak = async (refreshToken: string) => {
  const params = new URLSearchParams();
  params.append('refresh_token', refreshToken);
  params.append('grant_type', 'refresh_token');
  params.append('client_id', config.env.clientId);
  return await apiAuth.post(config.tokenEndpointKeycloak, params).then(
    //resolved
    (response) => {
      const token = response.data;
      setTokenKeycloak(token as TokenKeycloak);
      return token;
    },
    //rejected
    (error) => {
      localStorage.removeItem(ADMIN_TOKEN_KEYCLOAK);
      console.log('Logged out!');
      location.reload();
    },
  );
};
