import { IUser } from './types';
import { call, put } from 'redux-saga/effects';
import { meFailure, meSuccess, signInFailure, signInSuccess } from './reducer';
import { PayloadAction } from '@reduxjs/toolkit';
import { generateErrorMessage } from 'utils';
import api from 'api/middleware';
import apiAuth from 'api/middlewareAuth';
import { AxiosInstance } from 'axios';
import config from 'api/config';
import { ADMIN_TOKEN, ADMIN_TOKEN_KEYCLOAK } from 'api/helpers';

export function* signInSaga({
  payload: { email, password },
}: PayloadAction<{ email: string; password: string }>) {
  if (config.env.useKeycloak) {
    try {
      const params =
        `username=${encodeURIComponent(email)}&` +
        `password=${encodeURIComponent(password)}&` +
        `client_id=${encodeURIComponent(config.env.clientId)}&` +
        `grant_type=password`;
      const { data } = yield call<AxiosInstance>(
        apiAuth,
        config.tokenEndpointKeycloak,
        {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: params,
        },
      );

      localStorage.setItem(ADMIN_TOKEN_KEYCLOAK, JSON.stringify(data));
      yield put(signInSuccess());
    } catch (e) {
      console.log({ e });
      const error = e as { response: { status: number }; message: string };
      if (error.response.status === 404 || error.response.status === 400) {
        yield put(signInFailure('Invalid credentials (login or password)'));
      } else {
        yield put(signInFailure(error.message));
      }
    }
  } else {
    try {
      const { data } = yield call<AxiosInstance>(apiAuth, config.authToken, {
        method: 'POST',
        data: {
          email: email,
          password: password,
          audiences: [`${window.location.protocol}//${window.location.host}`],
        },
      });

      localStorage.setItem(ADMIN_TOKEN, JSON.stringify(data));
      yield put(signInSuccess());
    } catch (e) {
      const error = e as { response: { status: number } };
      if (error.response.status === 404 || error.response.status === 400) {
        yield put(signInFailure('Invalid credentials (login or password)'));
      } else {
        yield put(signInFailure(generateErrorMessage(e)));
      }
    }
  }
}

export function* meSaga() {
  try {
    const { data }: { data: IUser } = yield call<AxiosInstance>(
      api,
      config.usersMe,
    );

    yield put(meSuccess(data));
  } catch (e) {
    yield put(meFailure(generateErrorMessage(e)));
  }
}

export function* logoutSaga() {
  if (config.env.useKeycloak)
    yield call([localStorage, 'removeItem'], ADMIN_TOKEN_KEYCLOAK);
  else yield call([localStorage, 'removeItem'], ADMIN_TOKEN);
}
