import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { generateErrorMessage } from 'utils';
import { StatsRequestFilters } from './types';
import api from 'api/middleware';
import config from 'api/config';
import {
  getCustomersTopFailure,
  getCustomersTopSuccess,
  getItemsSoldFailure,
  getItemsSoldSuccess,
  getItemsTopFailure,
  getItemsTopSuccess,
  getOrdersCountFailure,
  getOrdersCountSuccess,
  getOrdersSumFailure,
  getOrdersSumSuccess,
  getOrderStatusesCountFailure,
  getOrderStatusesCountSuccess,
} from './reducer';

export function* getOrderStatusesCountSaga({
  payload,
}: PayloadAction<StatsRequestFilters>) {
  try {
    const { data } = yield call(api, config.stats + '/orders/statuses', {
      params: payload,
    });
    yield put(getOrderStatusesCountSuccess(data));
  } catch (e) {
    yield put(getOrderStatusesCountFailure(generateErrorMessage(e)));
  }
}

export function* getItemsTopSaga({
  payload,
}: PayloadAction<StatsRequestFilters>) {
  try {
    const { data } = yield call(api, config.stats + '/items/top', {
      params: payload,
    });
    yield put(getItemsTopSuccess(data));
  } catch (e) {
    yield put(getItemsTopFailure(generateErrorMessage(e)));
  }
}

export function* getItemsSoldSaga({
  payload,
}: PayloadAction<StatsRequestFilters>) {
  try {
    const { data } = yield call(api, config.stats + '/items/sold', {
      params: payload,
    });
    yield put(getItemsSoldSuccess(data));
  } catch (e) {
    yield put(getItemsSoldFailure(generateErrorMessage(e)));
  }
}

export function* getCustomersTopSaga({
  payload,
}: PayloadAction<StatsRequestFilters>) {
  try {
    const { data } = yield call(api, config.stats + '/customers/top', {
      params: payload,
    });
    yield put(getCustomersTopSuccess(data));
  } catch (e) {
    yield put(getCustomersTopFailure(generateErrorMessage(e)));
  }
}

export function* getOrdersSumSaga({
  payload,
}: PayloadAction<StatsRequestFilters>) {
  try {
    const { data } = yield call(api, config.stats + '/orders/sum', {
      params: payload,
    });
    yield put(getOrdersSumSuccess(data));
  } catch (e) {
    yield put(getOrdersSumFailure(generateErrorMessage(e)));
  }
}

export function* getOrdersCountSaga({
  payload,
}: PayloadAction<StatsRequestFilters>) {
  try {
    const { data } = yield call(api, config.stats + '/orders/count', {
      params: payload,
    });
    yield put(getOrdersCountSuccess(data));
  } catch (e) {
    yield put(getOrdersCountFailure(generateErrorMessage(e)));
  }
}
