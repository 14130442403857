import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DiscordRole, IDiscord } from './types';

const initialState: IDiscord = {
  roles: [],
  error: '',
  loading: false,
};

const slice = createSlice({
  name: 'discord',
  initialState,
  reducers: {
    getRolesRequest: (state) => {
      state.loading = true;
    },
    getRolesSuccess: (state, { payload }: PayloadAction<DiscordRole[]>) => {
      state.loading = false;
      state.roles = payload;
    },
    getRolesFailure: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    assignRolesRequest: (
      state,
      i: PayloadAction<{ customerId: number; roleIds: string[] }>,
    ) => {
      state.loading = true;
    },
    assignRolesSuccess: (state) => {
      state.loading = false;
    },
    assignRolesFailure: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const {
  getRolesRequest,
  getRolesSuccess,
  getRolesFailure,
  assignRolesRequest,
  assignRolesSuccess,
  assignRolesFailure,
} = slice.actions;
export const { reducer } = slice;
