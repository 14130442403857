import { Footer, LeftSidebar } from 'components';
import React, { Suspense, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { LAYOUT_DETACHED } from './constants';
import 'nprogress/nprogress.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Topbar = React.lazy(() => import('../components/Topbar'));
interface IProtectedLayoutProps {
  children: JSX.Element;
}

const ProtectedLayout: React.FunctionComponent<IProtectedLayoutProps> = ({
  children,
}) => {
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(true);

  const openMenu = () => {
    setIsMenuOpened((prevState: boolean) => {
      return !prevState;
    });
    if (document.body) {
      if (isMenuOpened) {
        document.body.classList.add('sidebar-enable');
      } else {
        document.body.classList.remove('sidebar-enable');
      }
    }
  };

  useEffect(() => {
    // set layout type
    if (document.body)
      document.body.setAttribute('data-layout', LAYOUT_DETACHED);
  }, []);

  return (
    <>
      <Suspense fallback={false}>
        <Topbar toggleSidebar={openMenu} />
      </Suspense>
      <Container fluid>
        <div className="wrapper">
          <Suspense fallback={false}>
            <LeftSidebar hideUserProfile />
          </Suspense>
          <div className="content-page">
            <div className="content">
              <Suspense fallback={false}>{children}</Suspense>
            </div>

            <Suspense fallback={false}>
              <Footer />
            </Suspense>
          </div>
        </div>
      </Container>
      <ToastContainer />
    </>
  );
};

export default ProtectedLayout;
