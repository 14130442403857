/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  INFTPayload,
  INFT,
  INFTState,
  ITemplate,
  IOrder,
  IOwner,
  INFTsData,
  IImportNFTData,
  INFTPayloadStepFirst,
  CreateUCTRequest,
  CreateUsecaseRequest,
  UpdateUCTRequest,
  UpdateUsecaseRequest,
  GetOrdersApi,
} from './types';
import { ApiUpdateAuctionRequest } from '../auction/types';

const initialState: INFTState = {
  nfts: null,
  nft: null,
  templates: null,
  orders: null,
  owners: null,
  loading: false,
  error: null,
  imported: false,
};

const nftSlice = createSlice({
  name: 'nft',
  initialState,
  reducers: {
    getNFTsRequest: (
      state,
      {
        payload,
      }: PayloadAction<{
        limit?: number;
        offset?: number;
        filter?: Record<string, string>;
        search?: string;
        lazy?: boolean;
      }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    getNFTsSuccess: (state, { payload }: PayloadAction<INFTsData>) => {
      state.loading = false;
      state.nfts = payload;
    },
    getNFTsFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    getNFTByIdRequest: (
      state,
      { payload }: PayloadAction<{ nftId: string }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    getNFTByIdSuccess: (state, { payload }: PayloadAction<INFT | null>) => {
      state.loading = false;
      state.nft = payload;
    },
    getNFTByIdFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    createNftRequest: (
      state,
      { payload }: PayloadAction<INFTPayloadStepFirst>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    createNftSuccess: (state) => {
      state.loading = false;
    },
    createNftFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    updateNftRequest: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        uuid?: string;
        body: Partial<INFTPayload>;
        redirect?: boolean;
        auction: ApiUpdateAuctionRequest | null;
        step?: string;
      }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    updateNftSuccess: (state, { payload }: PayloadAction<INFT>) => {
      state.loading = false;
      state.nft = payload;
    },
    updateNftFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    deleteNFTByIdRequest: (state, { payload }: PayloadAction<number>) => {
      state.loading = true;
      state.error = '';
    },
    deleteNFTByIdSuccess: (state, { payload }: PayloadAction<INFT | null>) => {
      state.loading = false;
      state.nft = payload;
    },
    deleteNFTByIdFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    getTemplatesRequest: (state) => {
      state.error = '';
    },
    getTemplatesSuccess: (state, { payload }: PayloadAction<ITemplate[]>) => {
      state.templates = payload;
    },
    getTemplatesFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    createUseCaseRequest: (
      state,
      {
        payload,
      }: PayloadAction<{ itemId: string; body: CreateUsecaseRequest }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    createUseCaseSuccess: (state) => {
      state.loading = false;
    },
    createUseCaseFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    deleteUseCaseRequest: (
      state,
      { payload }: PayloadAction<{ nftId: string; ucId: string }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    deleteUseCaseSuccess: (state) => {
      state.loading = false;
    },
    deleteUseCaseFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    getNftOrdersRequest: (state, { payload }: PayloadAction<GetOrdersApi>) => {
      state.loading = true;
      state.error = '';
    },
    getNftOrdersSuccess: (
      state,
      { payload }: PayloadAction<{ amount: number; list: IOrder[] }>,
    ) => {
      state.orders = payload;
      state.loading = false;
    },
    getNftOrdersFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    getOwnersRequest: (
      state,
      {
        payload,
      }: PayloadAction<{ nftId: string; limit: number; offset: number }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    getOwnersSuccess: (
      state,
      { payload }: PayloadAction<{ amount: number; list: IOwner[] }>,
    ) => {
      state.owners = payload;
      state.loading = false;
    },
    getOwnersFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    getAuctionNftsRequest: (state) => {
      state.loading = false;
      state.error = '';
    },
    getAuctionNftsSuccess: (state, { payload }: PayloadAction<INFT[]>) => {
      state.nfts = { amount: payload.length, list: payload };
      state.loading = false;
    },
    getAuctionNftsFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    importNftRequest: (state, { payload }: PayloadAction<IImportNFTData>) => {
      state.loading = true;
      state.error = '';
      state.imported = false;
    },
    importNftSuccess: (state) => {
      state.loading = false;
      state.imported = true;
    },
    importNftFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    createUseCaseTemplateRequest: (
      state,
      { payload }: PayloadAction<{ itemId: string; body: CreateUCTRequest }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    createUseCaseTemplateSuccess: (state) => {
      state.loading = false;
    },
    createUseCaseTemplateFailure: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.error = payload;
      state.loading = false;
    },
    editUseCaseRequest: (
      state,
      {
        payload,
      }: PayloadAction<{
        itemId: string;
        ucId: string;
        body: UpdateUsecaseRequest;
      }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    editUseCaseSuccess: (state) => {
      state.loading = false;
    },
    editUseCaseFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    deleteTemplateRequest: (
      state,
      { payload }: PayloadAction<{ templateId: string }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    deleteTemplateSuccess: (state) => {
      state.loading = false;
    },
    deleteTemplateFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    updateTemplateRequest: (
      state,
      {
        payload,
      }: PayloadAction<{ templateId: string; body: UpdateUCTRequest }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    updateTemplateSuccess: (state) => {
      state.loading = false;
    },
    updateTemplateFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    showEarlyAccessLinkRequest: (
      state,
      {
        payload,
      }: PayloadAction<{ date: Date; snapshotId: number; slug: string }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    showEarlyAccessLinkSuccess: (state) => {
      state.loading = false;
    },
    showEarlyAccessLinkFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    showShopPageRequest: (
      state,
      { payload }: PayloadAction<{ date: Date }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    showShopPageSuccess: (state) => {
      state.loading = false;
    },
    showShopPageFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const {
  getNFTsRequest,
  getNFTsSuccess,
  getNFTsFailure,
  getNFTByIdRequest,
  getNFTByIdSuccess,
  getNFTByIdFailure,
  createNftRequest,
  createNftSuccess,
  createNftFailure,
  updateNftRequest,
  updateNftSuccess,
  updateNftFailure,
  deleteNFTByIdRequest,
  deleteNFTByIdSuccess,
  deleteNFTByIdFailure,
  getTemplatesRequest,
  getTemplatesSuccess,
  getTemplatesFailure,
  createUseCaseRequest,
  createUseCaseSuccess,
  createUseCaseFailure,
  deleteUseCaseRequest,
  deleteUseCaseSuccess,
  deleteUseCaseFailure,
  getNftOrdersRequest,
  getNftOrdersSuccess,
  getNftOrdersFailure,
  getOwnersRequest,
  getOwnersSuccess,
  getOwnersFailure,
  getAuctionNftsRequest,
  getAuctionNftsSuccess,
  getAuctionNftsFailure,
  importNftRequest,
  importNftSuccess,
  importNftFailure,
  createUseCaseTemplateRequest,
  createUseCaseTemplateSuccess,
  createUseCaseTemplateFailure,
  editUseCaseRequest,
  editUseCaseSuccess,
  editUseCaseFailure,
  deleteTemplateRequest,
  deleteTemplateSuccess,
  deleteTemplateFailure,
  updateTemplateRequest,
  updateTemplateSuccess,
  updateTemplateFailure,
  showEarlyAccessLinkRequest,
  showEarlyAccessLinkSuccess,
  showEarlyAccessLinkFailure,
  showShopPageRequest,
  showShopPageSuccess,
  showShopPageFailure,
} = nftSlice.actions;

export const { reducer } = nftSlice;
