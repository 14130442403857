/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PromoCode,
  IPromoCodesState,
  CreatePromoCodesRequest,
  DeletePromoCodesRequest,
  GetPromoCodesRequest,
  UpdatePromoCodeRequest,
} from './types';

const initialState: IPromoCodesState = {
  list: null,
  amount: 0,
  error: null,
  loading: false,
};

const promoCodesSlice = createSlice({
  name: 'promoCodes',
  initialState,
  reducers: {
    createPromoCodesRequest: (
      state,
      i: PayloadAction<
        CreatePromoCodesRequest & { loadAfter?: Partial<GetPromoCodesRequest> }
      >,
    ) => {
      state.loading = true;
      state.error = '';
    },
    createPromoCodesSuccess: (
      state,
      { payload }: PayloadAction<{ count: number }>,
    ) => {
      state.loading = false;
    },
    createPromoCodesFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    getPromoCodesRequest: (state, i: PayloadAction<GetPromoCodesRequest>) => {
      state.loading = true;
      state.error = '';
    },
    getPromoCodesSuccess: (
      state,
      { payload }: PayloadAction<{ amount: number; list: PromoCode[] }>,
    ) => {
      state.loading = false;
      state.list = payload.list;
      state.amount = payload.amount;
    },
    getPromoCodesFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    updatePromoCodeRequest: (
      state,
      i: PayloadAction<
        UpdatePromoCodeRequest & { loadAfter?: Partial<GetPromoCodesRequest> }
      >,
    ) => {
      state.loading = true;
      state.error = '';
    },
    updatePromoCodeSuccess: (state) => {
      state.loading = false;
    },
    updatePromoCodeFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    deletePromoCodesRequest: (
      state,
      i: PayloadAction<
        DeletePromoCodesRequest & { loadAfter?: Partial<GetPromoCodesRequest> }
      >,
    ) => {
      state.loading = true;
      state.error = '';
    },
    deletePromoCodesSuccess: (state) => {
      state.loading = false;
    },
    deletePromoCodesFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const {
  createPromoCodesRequest,
  createPromoCodesSuccess,
  createPromoCodesFailure,
  getPromoCodesRequest,
  getPromoCodesSuccess,
  getPromoCodesFailure,
  updatePromoCodeRequest,
  updatePromoCodeSuccess,
  updatePromoCodeFailure,
  deletePromoCodesRequest,
  deletePromoCodesSuccess,
  deletePromoCodesFailure,
} = promoCodesSlice.actions;

export const { reducer } = promoCodesSlice;
