import { IMenuItem } from './types';

const findAllParent = (menuItems: IMenuItem[], menuItem: IMenuItem) => {
  let parents: string[] = [];
  const parent: IMenuItem | null = findMenuItem(
    menuItems,
    menuItem['parentKey'],
  );

  if (parent) {
    parents.push(parent['key']);
    if (parent['parentKey'])
      parents = [...parents, ...findAllParent(menuItems, parent)];
  }
  return parents;
};

const findMenuItem = (
  menuItems?: IMenuItem[],
  menuItemKey?: string,
): IMenuItem | null => {
  if (menuItems && menuItemKey) {
    for (let i = 0; i < menuItems.length; i++) {
      if (menuItems[i].key === menuItemKey) {
        return menuItems[i];
      }
      const found = findMenuItem(menuItems[i].children, menuItemKey);
      if (found) return found;
    }
  }
  return null;
};

export { findAllParent, findMenuItem };
