import { forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import { ForwardInputProps } from './types';

const ForwardInput = forwardRef(
  (
    {
      label,
      type,
      name,
      placeholder,
      register,
      error,
      className,
      labelClassName,
      containerClass,
      children,
      ...otherProps
    }: ForwardInputProps,

    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <Form.Group className={containerClass}>
        {label ? (
          <Form.Label className={labelClassName}>{label}</Form.Label>
        ) : null}

        <Form.Control
          type={type}
          placeholder={placeholder}
          name={name}
          id={name}
          className={className}
          isInvalid={Boolean(error)}
          {...(register ? register(name) : {})}
          {...otherProps}
          autoComplete={name}
          ref={ref}
        >
          {children ? children : null}
        </Form.Control>

        {error ? (
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        ) : null}
      </Form.Group>
    );
  },
);

export default ForwardInput;
