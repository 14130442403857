import { IConfigState } from './types';
import { createSelector } from 'reselect';

export const selectConfigType = createSelector(
  (state: { config: IConfigState }) => state.config,
  (config) => config.configType,
);

export const selectConfigTypes = createSelector(
  (state: { config: IConfigState }) => state.config,
  (config) => config.configTypes,
);

export const selectConfigs = createSelector(
  (state: { config: IConfigState }) => state.config,
  (config) => config.configs,
);

export const selectFetchConfig = createSelector(
  (state: { config: IConfigState }) => state.config,
  (config) => ({
    loading: config.loading,
    error: config.error,
  }),
);

export const selectEditedConfig = createSelector(
  (state: { config: IConfigState }) => state.config,
  (config) => config.config,
);

export const selectConfigFiles = createSelector(
  (state: { config: IConfigState }) => state.config,
  (config) => config.files,
);
