import { Row, Col } from 'react-bootstrap';
import { IPageTitleProps } from './types';
import React from 'react';

const PageTitle = ({ title, left, right, onBackClick }: IPageTitleProps) => {
  return (
    <Row className="py-1">
      <Col className="d-flex align-items-center min-w-500 ">
        <div className="d-flex align-items-center gap-2 page-title-box me-4 max-w-75-percent">
          {onBackClick && (
            <i
              className="mdi mdi-arrow-left font-24 icon-button"
              onClick={onBackClick}
            />
          )}
          <h4 className="page-title text-dark">{title}</h4>
        </div>
        {left}
      </Col>
      {Boolean(right) && (
        <Col
          className={'d-flex align-items-center justify-content-end ms-auto'}
        >
          {right}
        </Col>
      )}
    </Row>
  );
};

export default PageTitle;
