import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useLocation } from 'react-router';
import MENU_ITEMS from '../constants';
import { findAllParent, findMenuItem } from '../helpers';
import { IMenuItem } from '../types';
import MenuItem from './MenuItem';
import MenuItemWithChildren from './MenuItemWithChildren';

const AppMenu = () => {
  const menuRef = useRef(null);
  const location = useLocation();
  const [activeMenuItems, setActiveMenuItems] = useState<string[]>([]);

  /*
   * toggle the menus
   */
  const toggleMenu = (menuItem: IMenuItem, show: boolean) => {
    if (show)
      setActiveMenuItems([
        menuItem['key'],
        ...findAllParent(MENU_ITEMS, menuItem),
      ]);
  };

  /**
   * activate the menuitems
   */
  const activeMenu = useCallback(() => {
    const div = document.getElementById('main-side-menu');
    let matchingMenuItem = null;

    if (div) {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const items: HTMLCollectionOf<any> =
        div.getElementsByClassName('side-nav-link-ref');
      for (let i = 0; i < items.length; ++i) {
        if (location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }

      if (matchingMenuItem) {
        const mid: string = matchingMenuItem.getAttribute('data-menu-key');
        const activeMt = findMenuItem(MENU_ITEMS, mid);
        if (activeMt) {
          setActiveMenuItems([
            activeMt['key'],
            ...findAllParent(MENU_ITEMS, activeMt),
          ]);
        }
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    activeMenu();
  }, [activeMenu]);

  return (
    <>
      <ul className="side-nav pt-2" ref={menuRef} id="main-side-menu">
        {(MENU_ITEMS || []).map((item: IMenuItem, idx: number) => {
          return (
            <React.Fragment key={idx}>
              {item.isTitle ? (
                <li hidden className="side-nav-title side-nav-item">
                  {item.label}
                </li>
              ) : (
                <>
                  {item.children ? (
                    <MenuItemWithChildren
                      item={item}
                      toggleMenu={toggleMenu}
                      subMenuClassNames="side-nav-second-level"
                      activeMenuItems={activeMenuItems}
                      linkClassName="side-nav-link"
                    />
                  ) : (
                    <MenuItem
                      item={item}
                      linkClassName="side-nav-link"
                      className={
                        activeMenuItems.includes(item.key)
                          ? 'menuitem-active'
                          : ''
                      }
                    />
                  )}
                </>
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </>
  );
};

export default AppMenu;
