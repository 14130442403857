/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStatsState, StatsRequestFilters, StatsType } from './types';
import { generateInitStatsValues } from './utils';
import { firstLetterUp } from 'utils';
import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

const initialState: IStatsState = {
  data: generateInitStatsValues([]),
  errors: generateInitStatsValues(''),
  loading: generateInitStatsValues(false),
};

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: Object.values(StatsType).reduce(
    (acc, type: StatsType) => ({
      ...acc,
      [`get${firstLetterUp(type)}Request`]: (
        state: IStatsState,
        { payload }: PayloadAction<StatsRequestFilters>,
      ) => {
        state.loading[type] = true;
        state.errors[type] = '';
      },
      [`get${firstLetterUp(type)}Success`]: (
        state: IStatsState,
        { payload }: PayloadAction<IStatsState['data'][typeof type]>,
      ) => {
        state.loading[type] = false;
        state.data = { ...state.data, [type]: payload };
      },
      [`get${firstLetterUp(type)}Failure`]: (
        state: IStatsState,
        { payload }: PayloadAction<string>,
      ) => {
        state.errors[type] = payload;
        state.loading[type] = false;
      },
    }),
    {} as SliceCaseReducers<IStatsState>,
  ),
});

export const {
  getOrderStatusesCountRequest,
  getOrderStatusesCountSuccess,
  getOrderStatusesCountFailure,
  getItemsTopRequest,
  getItemsTopSuccess,
  getItemsTopFailure,
  getItemsSoldRequest,
  getItemsSoldSuccess,
  getItemsSoldFailure,
  getCustomersTopRequest,
  getCustomersTopSuccess,
  getCustomersTopFailure,
  getOrdersSumRequest,
  getOrdersSumSuccess,
  getOrdersSumFailure,
  getOrdersCountRequest,
  getOrdersCountSuccess,
  getOrdersCountFailure,
} = statsSlice.actions;

export const requestActions = [
  getOrderStatusesCountRequest,
  getItemsTopRequest,
  getItemsSoldRequest,
  getCustomersTopRequest,
  getOrdersSumRequest,
  getOrdersCountRequest,
];

export const { reducer } = statsSlice;
