import { createSelector } from 'reselect';
import { IAuthState } from './types';

export const selectAuthInfo = createSelector(
  (state: { auth: IAuthState }) => state.auth,
  (auth) => auth,
);

export const selectMe = createSelector(
  (state: { auth: IAuthState }) => state.auth,
  (auth) => auth.user,
);
