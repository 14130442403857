/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Config,
  ConfigType,
  IConfigState,
  IUpdateConfigRequest,
} from './types';

const initialState: IConfigState = {
  configs: null,
  config: null,
  configTypes: null,
  configType: null,
  files: { loading: false, error: null, urls: [] },
  error: null,
  loading: false,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    getConfigTypeRequest: (
      state,
      { payload }: PayloadAction<{ name: string }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    getConfigTypeSuccess: (
      state,
      { payload }: PayloadAction<ConfigType | null>,
    ) => {
      state.loading = false;
      state.configType = payload;
    },
    getConfigTypeFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    getConfigTypesRequest: (state) => {
      state.loading = true;
      state.error = '';
    },
    getConfigTypesSuccess: (
      state,
      { payload }: PayloadAction<ConfigType[]>,
    ) => {
      state.loading = false;
      state.configTypes = payload;
    },
    getConfigTypesFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    getConfigsRequest: (
      state,
      { payload }: PayloadAction<{ type: string }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    getConfigsSuccess: (state, { payload }: PayloadAction<Config[] | null>) => {
      state.loading = false;
      state.configs = payload;
    },
    getConfigsFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    getConfigByIdOrNameRequest: (
      state,
      { payload }: PayloadAction<string | number>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    getConfigByIdOrNameSuccess: (
      state,
      { payload }: PayloadAction<Config | null>,
    ) => {
      state.loading = false;
      state.config = payload;
    },
    getConfigByIdOrNameFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    createConfigRequest: (
      state,
      {
        payload,
      }: PayloadAction<{
        name: string;
        typeId: number;
        json?: Record<string, any>;
      }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    createConfigSuccess: (state) => {
      state.loading = false;
    },
    createConfigFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    updateConfigRequest: (
      state,
      {
        payload,
      }: PayloadAction<{ configId: number; body: IUpdateConfigRequest }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    updateConfigSuccess: (state, { payload }: PayloadAction<Config>) => {
      state.loading = false;
      state.config = payload;
    },
    updateConfigFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    deleteManyConfigsRequest: (state, { payload }: PayloadAction<number[]>) => {
      state.loading = true;
      state.error = '';
    },
    deleteManyConfigsSuccess: (state) => {
      state.loading = false;
    },
    deleteManyConfigsFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    encryptDataRequest: (
      state,
      { payload }: PayloadAction<{ data: string; cb?: (hash: string) => void }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    encryptDataSuccess: (state) => {
      state.loading = false;
    },
    encryptDataFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    copyPasteConfigRequest: (
      state,
      { payload }: PayloadAction<{ fromId: number; toId: number }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    copyPasteConfigSuccess: (state) => {
      state.loading = false;
    },
    copyPasteConfigFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    uploadConfigFilesRequest: (
      state,
      {
        payload,
      }: PayloadAction<{ configId: number; type: string; files: File[] }>,
    ) => {
      state.files.loading = true;
      state.files.error = '';
    },
    uploadConfigFilesSuccess: (state, { payload }: PayloadAction<string[]>) => {
      state.files.loading = false;
      state.files.urls = payload;
    },
    uploadConfigFilesFailure: (state, { payload }: PayloadAction<string>) => {
      state.files.error = payload;
      state.files.loading = false;
    },
  },
});

export const {
  getConfigTypeRequest,
  getConfigTypeSuccess,
  getConfigTypeFailure,
  getConfigTypesRequest,
  getConfigTypesSuccess,
  getConfigTypesFailure,
  getConfigsRequest,
  getConfigsSuccess,
  getConfigsFailure,
  getConfigByIdOrNameRequest,
  getConfigByIdOrNameSuccess,
  getConfigByIdOrNameFailure,
  createConfigRequest,
  createConfigSuccess,
  createConfigFailure,
  updateConfigRequest,
  updateConfigSuccess,
  updateConfigFailure,
  deleteManyConfigsRequest,
  deleteManyConfigsSuccess,
  deleteManyConfigsFailure,
  encryptDataRequest,
  encryptDataSuccess,
  encryptDataFailure,
  copyPasteConfigRequest,
  copyPasteConfigSuccess,
  copyPasteConfigFailure,
  uploadConfigFilesRequest,
  uploadConfigFilesSuccess,
  uploadConfigFilesFailure,
} = configSlice.actions;

export const { reducer } = configSlice;
