import { createBrowserHistory } from 'history';
import { Route, Router, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { protectedRoutes, publicRoutes } from './routes';
import PublicRoute from './PublicRoute';
import PageNotFound from 'pages/errors/PageNotFound';
import { v4 } from 'uuid';

export const history = createBrowserHistory();

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        {publicRoutes.map(({ path, component }) => (
          <PublicRoute exact path={path} key={v4()}>
            {component}
          </PublicRoute>
        ))}
        {protectedRoutes.map(({ path, component }) => (
          <PrivateRoute exact path={path} key={v4()}>
            {component}
          </PrivateRoute>
        ))}
        <Route component={PageNotFound} path="*" />
      </Switch>
    </Router>
  );
};

export default Routes;
