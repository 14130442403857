/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, select } from 'redux-saga/effects';
import {
  getSCTemplateFailure,
  getSCTemplateSuccess,
  getSmartContractsSuccess,
  getBlockChainSuccess,
  getSmartContractSuccess,
  getTokensSuccess,
  createSmartContractRequest,
  createTokenRequest,
  createTokenFailure,
  fulfillOrderSuccess,
  getBlockChainsSuccess,
  PrepareFreezeSuccess,
  prepareFreezeFailure,
} from './reducer';
import { generateErrorMessage } from 'utils';
import api from 'api/middleware';
import config from 'api/config';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  ApiFreezeContract,
  ApiGetContracts,
  ApiGetTokens,
  ApiPrepareFreeze,
  BlockchainFulfillOrdersPayload,
  IBlockChain,
  ISmartContract,
  ISmartContractTemplateApi,
  ITokenApi,
  OrdersPatchApi,
} from './types';
import { history } from 'navigation';
import { generatePath } from 'react-router';
import routes from 'navigation/paths';
import { ethers } from 'ethers';
import { INFTsData } from 'redux/nft/types';
import {
  OrderStatus,
  toastSettings,
  CONTRACTS_MINT_ONLY,
  CONTRACTS_SUPPORT_CREATION,
} from 'constants/global';
import { getNftOrdersRequest } from '../nft/reducer';
import { selectSmartContract, selectSmartContracts } from './selectors';
import { abiBatchTransferFromCreator } from './abiBatchTransferFromCreator';
import { abiFreeze } from './abiFreeze';
import { abiMint } from './abiMint';
import { abiMint721 } from './abiMint721';
import { toast } from 'react-toastify';

export function* getBlockChain({ payload }: PayloadAction<string>) {
  try {
    const { data } = yield call(api, config.blockchains, {
      params: { chainId: payload },
    });
    yield put(getBlockChainSuccess(data));
  } catch (e) {
    yield put(getSCTemplateFailure(generateErrorMessage(e)));
  }
}

export function* getBlockChains() {
  try {
    const { data } = yield call(api, config.blockchains);
    yield put(getBlockChainsSuccess(data));
  } catch (e) {
    yield put(getSCTemplateFailure(generateErrorMessage(e)));
  }
}

export function* getSmartContractTemplate({ payload }: PayloadAction<number>) {
  try {
    const { data } = yield call(api, config.smartContractTemplate(payload));
    yield put(getSCTemplateSuccess(data));
  } catch (e) {
    yield put(getSCTemplateFailure(generateErrorMessage(e)));
  }
}

export function* getSmartContracts({
  payload,
}: PayloadAction<ApiGetContracts>) {
  try {
    const {
      data: { list, amount },
    } = yield call(api, config.smartContracts, {
      params: payload,
    });
    const contractsData: INFTsData = yield select(selectSmartContracts);
    yield put(
      getSmartContractsSuccess({
        list: [...(contractsData.list || []), ...list],
        amount,
      }),
    );
  } catch (e) {
    yield put(getSCTemplateFailure(generateErrorMessage(e)));
  }
}

export function* deleteSmartContractTemplate({
  payload,
}: PayloadAction<number>) {
  try {
    yield call(api.delete, config.smartContractTemplate(payload));
    history.push(routes.blockchain);
  } catch (e) {
    yield put(getSCTemplateFailure(generateErrorMessage(e)));
  }
}

export function* createSmartContractTemplate({
  payload,
}: PayloadAction<ISmartContractTemplateApi>) {
  try {
    const { data } = yield call(
      api.post,
      config.smartContractTemplates,
      payload,
    );
    history.push(
      generatePath(routes.blockchainTemplate, { templateId: data.id }),
    );
  } catch (e) {
    yield put(getSCTemplateFailure(generateErrorMessage(e)));
  }
}

export function* updateSmartContractTemplate({
  payload: { id, ...body },
}: PayloadAction<ISmartContractTemplateApi>) {
  try {
    const { data } = yield call(
      api.patch,
      config.smartContractTemplate(id as number),
      body,
    );
    history.push(
      generatePath(routes.blockchainTemplate, { templateId: data.id }),
    );
  } catch (e) {
    yield put(getSCTemplateFailure(generateErrorMessage(e)));
  }
}
export function* getSmartContract({ payload }: PayloadAction<number>) {
  try {
    const { data } = yield call(api, config.smartContract(payload));
    yield put(getSmartContractSuccess(data));
  } catch (e) {
    yield put(getSCTemplateFailure(generateErrorMessage(e)));
  }
}

export function* createSmartContract({
  payload,
}: PayloadAction<ISmartContractTemplateApi>) {
  try {
    console.log('payload in createSmartcontract:', payload);
    const { data } = yield call(api.post, config.smartContracts, payload);
    history.push(
      generatePath(routes.blockchainSmartContract, {
        contractId: data.id,
      }),
    );
    yield put(getSmartContractSuccess(data));
  } catch (e) {
    yield put(getSCTemplateFailure(generateErrorMessage(e)));
  }
}

export function* updateSmartContract({
  payload: { id, ...body },
}: PayloadAction<ISmartContractTemplateApi>) {
  try {
    const { data } = yield call(
      api.patch,
      config.smartContract(id as number),
      body,
    );
    history.push(
      generatePath(routes.blockchainSmartContract, {
        contractId: id,
      }),
    );
    yield put(getSmartContractSuccess(data));
  } catch (e) {
    yield put(getSCTemplateFailure(generateErrorMessage(e)));
  }
}

export function* createToken({ payload }: PayloadAction<ITokenApi>) {
  try {
    yield call(api.post, config.tokens, payload);
    history.push(
      generatePath(routes.blockchainSmartContract, {
        contractId: payload.contractId,
      }),
    );
  } catch (e) {
    yield put(getSCTemplateFailure(generateErrorMessage(e)));
  }
}

export function* getTokens({ payload }: PayloadAction<ApiGetTokens>) {
  try {
    const { data } = yield call(api, config.tokens, {
      params: payload,
    });
    yield put(getTokensSuccess(data));
  } catch (e) {
    yield put(getSCTemplateFailure(generateErrorMessage(e)));
  }
}

export function* blockchainCreateContract({
  payload,
}: PayloadAction<any>): any {
  //For now it looks like the constructor has the same arguments for all Contracts
  //so no need to differentiate by ContractTemplate type.
  const { setError } = payload;
  try {
    const { template, values, library } = payload;
    console.log('payload:', payload);
    if (template) {
      const abi = JSON.parse(template.abi);
      const bytecode = template.byteCode;
      const options = {};
      if (library) {
        const contractFactory = new ethers.ContractFactory(
          abi,
          bytecode,
          library.getSigner(),
        );

        const normalizedContractName = values.symbol;

        const baseURL =
          config.env.apiBaseURL +
          config.metadata +
          normalizedContractName +
          '/';
        console.log('baseURL:', baseURL);

        const deployedContract: any = yield contractFactory.deploy(
          baseURL,
          values.name,
          values.symbol,
          options,
        );
        console.log('deployedContract:', deployedContract);
        yield toast.success(
          `deployedContract: ${deployedContract.address}`,
          toastSettings,
        );
        if (deployedContract?.deployTransaction) {
          yield deployedContract.deployTransaction.wait();
          values['address'] = deployedContract.address;
          values['creationCode'] = deployedContract.deployTransaction.data;
          const constructorArgs = values['creationCode'].replace(bytecode, '');
          values['constructorArgs'] = constructorArgs;
          console.log('values:', values);
          yield put(
            createSmartContractRequest({ ...values, templateId: template.id }),
          );
        }
      }
    }
  } catch (e: any) {
    setError(e?.message || e || 'Hm');
    yield put(getSCTemplateFailure(generateErrorMessage(e)));
  }
}

export function* blockchainCreateTokenSaga({
  payload,
}: PayloadAction<any>): any {
  const { contractId, itemId, isVirtualToken } = payload;
  const { creatorAddress, initialSupply, totalSupply } = payload;
  const { smartContract, chainId, library } = payload;
  const { setError } = payload;
  console.log({ smartContract });
  console.log({ isVirtualToken, contractId, itemId, totalSupply });
  if (isVirtualToken) {
    yield put(
      createTokenRequest({
        contractId,
        tokenId: -1,
        totalSupply,
        itemId,
      }),
    );
    return;
  }
  try {
    const { data: smartContractTemplate } = yield call(
      api,
      config.smartContractTemplate(smartContract.templateId),
    );
    console.log({ smartContractTemplate });
    if (!CONTRACTS_SUPPORT_CREATION.includes(smartContractTemplate.type)) {
      const message = 'The Contract does not support Token Creation';
      throw { message };
    }
    const { data: blockchain } = yield call(api, config.blockchains, {
      params: { chainId },
    });
    console.log({
      blockchain,
      chainId,
      blockchainId: smartContract.blockchainId,
    });
    if (blockchain.id != smartContract.blockchainId) {
      const message =
        'You should be connected to the same blockchain that the contract is on!';
      console.log({ error: message });
      throw message;
    }
    const contract = new ethers.Contract(
      smartContract.address,
      smartContractTemplate.abi,
      library.getSigner(),
    );

    const options = {};

    //getting the next tokenId
    const { data: tokens } = yield call(api, config.tokens, {
      params: { contractId: smartContract.id },
    });
    //If there are already 3 tokens, then the next tokenId is 3
    //because we start with 0
    const tokenId = tokens.amount;

    let alreadyCreated = false;
    try {
      if (smartContractTemplate.type === 'DematLazySupply') {
        const maxSupply = yield contract.maxSupply(tokenId);
        alreadyCreated = maxSupply > 0;
      } else {
        alreadyCreated = yield contract.exists(tokenId);
      }
    } catch (alreadyCreatedError: any) {
      console.log({ alreadyCreatedError });
    }

    let tx: any = '';
    if (!alreadyCreated) {
      console.log({
        creatorAddress,
        tokenId,
        initialSupply,
        totalSupply,
        options,
      });
      if (smartContractTemplate.type === 'DematLazySupply') {
        try {
          tx = yield contract.setMaxSupply(
            tokenId,
            parseInt(totalSupply),
            options,
          );
        } catch (createError: any) {
          console.log({ createError });
          setError(createError.message);
          throw createError.message;
        }
      } else {
        try {
          tx = yield contract.create(
            creatorAddress,
            tokenId,
            parseInt(initialSupply),
            '',
            [],
            options,
          );
        } catch (createError: any) {
          console.log({ createError });
          setError(createError.message);
          throw createError.message;
        }
      }
      console.log({ tx });
      yield tx.wait();
      yield toast.success(`tx ${tx.id}`, toastSettings);
    }

    if (smartContractTemplate.type === 'ERC1155') {
      //other Contracts should have this implemented in Solidity
      try {
        tx = yield contract.setCreator(creatorAddress, [tokenId], options);
      } catch (setCreatorError: any) {
        console.log({ setCreatorError });
        setError(setCreatorError.message);
        throw setCreatorError.message;
      }
      yield tx.wait();
      yield toast.success(`Set creator to ${creatorAddress}`, toastSettings);
    }

    yield put(createTokenRequest({ contractId, tokenId, itemId, totalSupply }));
  } catch (e: any) {
    console.log({ error: e });
    setError(e?.message || e || 'Hm');
    yield put(createTokenFailure(generateErrorMessage(e)));
  }
}

export function* fulFillOrders({
  payload: { orders, ...data },
}: PayloadAction<OrdersPatchApi>) {
  try {
    console.log({ where: 'fulFillOrders', orders });
    yield call(api.patch, config.orders, {
      orderIds: orders.map((o) => o.id),
      status: OrderStatus.FULFILLED,
    });
    yield put(fulfillOrderSuccess());
    yield put(getNftOrdersRequest(data));
  } catch (e) {
    console.log({ error: e });
    yield put(createTokenFailure(generateErrorMessage(e)));
  }
}

export function* blockchainFulfillOrders({
  payload,
}: PayloadAction<BlockchainFulfillOrdersPayload>): any {
  const { account, chainId, library, setError, fulfillType } = payload;
  try {
    console.log({ where: 'start of blockchainFulfillOrders' });
    const { orders, ...data } = payload;

    const { data: blockchain } = yield call(api, config.blockchains, {
      params: { chainId },
    });

    const smartContract: ISmartContract = yield select(selectSmartContract);
    console.log({ smartContract });

    if (!smartContract) {
      const message = 'There is no smart contract for the orders';
      setError(message);
      throw message;
    }

    const { data: smartContractTemplate } = yield call(
      api,
      config.smartContractTemplate(smartContract.templateId),
    );
    console.log({ smartContractTemplate });
    if (smartContractTemplate.type === 'ERC1155' && fulfillType === 'mint') {
      const message = 'The Contract does not support Minting';
      throw { message };
    }
    if (
      CONTRACTS_MINT_ONLY.includes(smartContractTemplate.type) &&
      fulfillType === 'transfer'
    ) {
      const message = 'The Contract does not support Transfer from Creators';
      throw { message };
    }

    console.log({
      blockchain,
      chainId,
      blockchainId: smartContract.blockchainId,
    });

    if (blockchain.id != smartContract.blockchainId) {
      const message =
        'You should be connected to the same blockchain that the contract is on!';
      console.log({ error: message });
      setError(message);
      throw message;
    }

    if (!smartContract.address) throw 'Smart contract has no address!';

    let contract: any;
    if (fulfillType === 'transfer') {
      contract = new ethers.Contract(
        smartContract.address,
        abiBatchTransferFromCreator,
        library.getSigner(),
      );
    }
    if (fulfillType === 'mint') {
      if (smartContractTemplate.type === 'DematLazySupply') {
        contract = new ethers.Contract(
          smartContract.address,
          abiMint,
          library.getSigner(),
        );
      } else if (smartContractTemplate.type === 'ERC721Minimal') {
        contract = new ethers.Contract(
          smartContract.address,
          abiMint721,
          library.getSigner(),
        );
      } else {
        const message = 'Unsupported minting contract type';
        setError(message);
        throw message;
      }
    }

    const owner = yield contract.owner();
    console.log({ owner, account });
    if (owner !== account) {
      const message = `You should be the owner(${owner}) of the Contract!`;
      setError(message);
      throw message;
    }

    const tokenIds = [];
    const to = [];
    const orderIds = [];
    for (const i in orders) {
      const order = orders[i];
      console.log({ where: 'Starting to fulfill Order', order });
      yield toast.success(
        `Starting to fulfill Order ${order.id}`,
        toastSettings,
      );

      const fulfillmentBlockchainToken = order.item?.tokens?.find(
        (token) =>
          token.contract.blockchain.id === order.fulfillmentBlockchainId,
      );
      const firstToken = order.item?.tokens?.[0];
      const token = fulfillmentBlockchainToken || firstToken;
      const tokenContract = token?.contract;

      if (tokenContract?.address !== smartContract.address) {
        console.log({ where: 'Skipping order because wrong contract', order });
        yield toast.success(
          `Skipping order because wrong contract ${order.id}`,
          toastSettings,
        );
        continue;
      }

      const orderChainId =
        order.fulfillmentBlockchain?.chainId ||
        order.item?.tokens[0].contract?.blockchain.chainId;

      if (orderChainId !== chainId) {
        console.log({
          where: 'Skipping order because wrong blockchain',
          order,
          orderChainId,
          chainId,
        });
        yield toast.success(
          `Skipping order because wrong blockchain ${order.id} ${orderChainId} ${chainId}`,
          toastSettings,
        );
        continue;
      }

      let tokenId = token?.tokenId;
      if (tokenId === undefined || tokenId === null) {
        console.log({ where: 'Skipping order because no tokenId', order });
        yield toast.success(
          `Skipping order because no tokenId ${order.id}`,
          toastSettings,
        );
        continue;
      }
      if (tokenId == -1) {
        //-1 stands for in order fulfillment
        //  fulfillmentItemIds should be filled in BE
        const concreteItemId = order.fulfillmentItemIds[0];
        if (concreteItemId) {
          yield toast.success(
            `Fulfilling Virtual Item (orderId = ${order.id}), using itemId = ${concreteItemId}`,
            toastSettings,
          );
          const { data: concreteItem } = yield call(
            api,
            config.nftById(String(concreteItemId)),
          );

          const fulfillmentBlockchainToken = concreteItem.tokens.find(
            (token: { blockchain: IBlockChain }) =>
              token.blockchain.id === order.fulfillmentBlockchainId,
          );
          const firstToken = concreteItem.tokens[0];
          const concreteItemTokenId = (fulfillmentBlockchainToken || firstToken)
            ?.tokenId;

          if (concreteItemTokenId >= 0) {
            tokenId = concreteItemTokenId;
          } else {
            yield toast.error(`No concrete Item found!`, toastSettings);
            continue;
          }
        } else {
          yield toast.error(
            `No fulfillmentItemIds[0] for orderId = ${order.id}`,
            toastSettings,
          );
          continue;
        }
      }
      if (fulfillType === 'transfer') {
        const creator = yield contract.creators(tokenId);
        if (!creator) {
          console.log({ where: 'Skipping order because no creator', order });
          yield toast.success(
            `Skipping order because no creator ${order.id}`,
            toastSettings,
          );
          continue;
        }
      }
      const blockchainDeliveryAddress = order.blockchainDeliveryAddress;
      if (!blockchainDeliveryAddress) {
        console.log({
          where: 'Skipping order because no delivery address',
          order,
        });
        yield toast.success(
          `Skipping order because no delivery address ${order.id}`,
          toastSettings,
        );
        continue;
      }
      if (order.status !== OrderStatus.FULFILLING) {
        console.log({
          where: 'Skipping order because status not FULFILLING',
          order,
        });
        yield toast.success(
          `Skipping order because status not FULFILLING ${order.id}`,
          toastSettings,
        );
        continue;
      }
      //TODO: check supply?

      if (tokenIds.length >= 100) {
        console.log({
          where: 'Skipping order because more than 100 orders',
          order,
        });
        continue;
      }
      tokenIds.push(tokenId);
      to.push(blockchainDeliveryAddress);
      orderIds.push(order.id);
    }
    if (to.length === 0) {
      console.log({
        where: 'Checking if there is anything to do.',
        message: 'No orders to fulfill, finishing!',
      });
      yield put(fulfillOrderSuccess());
      return;
    }
    const options = {
      gasLimit: 50000 * (tokenIds.length + 1), //50k additional
    };

    let tx: any = '';
    console.log({
      where: 'Before fulfilling Order',
      tokenIds,
      to,
      options,
    });

    try {
      if (fulfillType === 'transfer') {
        tx = yield contract.batchTransferFromCreators(
          tokenIds,
          to,
          [],
          options,
        );
      } else if (fulfillType === 'mint') {
        if (smartContractTemplate.type === 'DematLazySupply') {
          tx = yield contract.batchMintMultipleReceivers(
            to,
            tokenIds,
            [],
            options,
          );
        }
        if (smartContractTemplate.type === 'ERC721Minimal') {
          tx = yield contract.batchMintMultipleReceivers(to, tokenIds, options);
        }
      } else {
        throw { message: 'Unknown fulfillment type!' };
      }
    } catch (transferTokenError: any) {
      console.log({ transferTokenError });
      setError(transferTokenError.message);
      throw transferTokenError.message;
    }
    console.log({ where: 'After sending the transaction', tx });
    yield toast.success(
      `After sending the transaction ${tx.id}`,
      toastSettings,
    );

    yield tx.wait();

    console.log({ where: 'After waiting on the transaction', tx });

    yield call(api.patch, config.orders, {
      orderIds: orderIds,
      blockchainTransactionId: tx.hash,
      status: OrderStatus.FULFILLED,
    });

    console.log({
      where: 'After fulfilling Orders',
    });
    yield put(getNftOrdersRequest(data));
    yield put(fulfillOrderSuccess());
    console.log({ where: 'end of blockchainFulfillOrders' });
    yield toast.success(`Orders fulfilled`, toastSettings);
  } catch (e: any) {
    console.log({ where: 'blockchainFulfillOrders', error: e });
    setError(e?.message || e || 'Hm');
    yield put(createTokenFailure(generateErrorMessage(e)));
  }
}

export function* prepareFreeze({ payload }: PayloadAction<ApiPrepareFreeze>) {
  try {
    const { contractId, types } = payload;
    yield call(api.post, config.prepareFreeze(contractId), {
      types,
    });
    const { data } = yield call(api, config.smartContract(contractId));
    yield put(getSmartContractSuccess(data));
    yield put(PrepareFreezeSuccess());
  } catch (e) {
    console.log({ error: e });
    yield put(prepareFreezeFailure(generateErrorMessage(e)));
  }
}

export function* FreezeContract({
  payload,
}: PayloadAction<ApiFreezeContract>): any {
  try {
    console.log('FreezeContract');
    const { account, chainId, library, freeze } = payload;
    yield toast.success(
      'Starting to Freeze Contract on Blockchain',
      toastSettings,
    );
    console.log({ freeze });
    if (!freeze || !freeze.metadataCID || !(freeze.status === 'READY')) {
      const message = 'Something is wrong with the Freeze';
      yield toast.error(message, toastSettings);
      console.log({ freeze });
      throw { message };
    }
    const { data: blockchain } = yield call(api, config.blockchains, {
      params: { chainId },
    });

    const smartContract: ISmartContract = yield select(selectSmartContract);
    console.log({ smartContract });

    if (!smartContract) {
      const message = 'There is no smart contract?';
      yield toast.error(message, toastSettings);
      throw message;
    }

    console.log({
      blockchain,
      chainId,
      blockchainId: smartContract.blockchainId,
    });

    if (blockchain.id != smartContract.blockchainId) {
      const message =
        'You should be connected to the same blockchain that the contract is on!';
      console.log({ error: message });
      yield toast.error(message, toastSettings);
      throw message;
    }

    yield toast.success(
      'The Blockchain you are connected to matches the Contract',
      toastSettings,
    );

    if (!smartContract.address) {
      const message = 'Smart Contract has no address!';
      yield toast.error(message, toastSettings);
      throw message;
    }
    if (!library) {
      const message = 'Library is undefined, is the Wallet connected?';
      yield toast.error(message, toastSettings);
      throw message;
    }
    const contract = new ethers.Contract(
      smartContract.address,
      abiFreeze,
      library.getSigner(),
    );
    const owner = (yield contract.owner()) as string;
    console.log({ owner, account });
    if (owner !== account) {
      const message = `You should be the owner(${owner}) of the Contract!`;
      yield toast.error(message, toastSettings);
      throw message;
    }

    yield toast.success('You are the Owner of the Contract', toastSettings);
    const options = {};
    let tx: any = '';
    try {
      //For now does not depend on ContractTemplate.type
      tx = (yield contract.setBaseURI(
        `ipfs://${freeze.metadataCID}/`,
        options,
      )) as any;
    } catch (setBaseUriError: any) {
      console.log({ setBaseUriError });
      yield toast.error(
        `Error while setting base URI: ${setBaseUriError.message}`,
        toastSettings,
      );
      throw setBaseUriError.message;
    }
    console.log({ where: 'After sending the transaction', tx });
    yield toast.success(
      `After sending the transaction ${tx.id}`,
      toastSettings,
    );

    yield tx.wait();

    yield toast.success('Transaction accepted!', toastSettings);
    console.log({ where: 'After waiting on the transaction', tx });

    yield call(api.post, config.freeze(freeze.id));

    yield toast.success('Contract Frozen', toastSettings);

    yield put(PrepareFreezeSuccess());
  } catch (e) {
    console.log({ error: e });
    yield put(createTokenFailure(generateErrorMessage(e)));
  }
}
