import { IMenuItem } from './types';
import routes from 'navigation/paths';

const MENU_ITEMS: IMenuItem[] = [
  { key: 'navigation', label: 'Navigation', isTitle: true },
  {
    key: 'dashboard',
    label: 'Dashboard',
    isTitle: false,
    icon: 'mdi mdi-monitor-dashboard',
    url: routes.dashboard,
  },
  {
    key: 'drips',
    label: 'Drips',
    isTitle: false,
    icon: 'uil-folder-download',
    url: routes.drops,
  },
  {
    key: 'nfts',
    label: 'NFTs',
    isTitle: false,
    icon: 'uil-layer-group',
    url: routes.nfts,
  },
  {
    key: 'customers',
    label: 'Customers',
    isTitle: false,
    icon: 'uil-user-circle',
    url: routes.customers,
  },
  {
    key: 'orders',
    label: 'Orders',
    isTitle: false,
    icon: 'mdi mdi-cart',
    url: routes.orders,
  },
  {
    key: 'auctions',
    label: 'Auctions',
    isTitle: false,
    icon: 'mdi mdi-hammer',
    url: routes.auctions,
  },
  {
    key: 'blockchain',
    label: 'Blockchain',
    isTitle: false,
    icon: 'uil-database-alt',
    url: routes.blockchain,
  },
  {
    key: 'newstickers',
    label: 'Newstickers',
    isTitle: false,
    icon: 'mdi mdi-form-textbox-password',
    url: routes.newstickers,
  },
  {
    key: 'configurations',
    label: 'Configurations',
    isTitle: false,
    icon: 'mdi mdi-table-cog',
    url: routes.configTypes,
  },
];

export default MENU_ITEMS;
