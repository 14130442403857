import { IPaginationProps } from './types';

const Pagination = ({
  tableProps,
  sizePerPageList,
  pageCount = 1,
  currentPage = 1,
  setCurrentPage,
  changeLimit,
  amount = 0,
}: IPaginationProps) => {
  return (
    <>
      <div className="d-lg-flex align-items-center text-center px-2 py-1 text-dark">
        <label className="me-1">Display :</label>
        <select
          value={tableProps.state.pageSize}
          onChange={(e) => {
            tableProps.setPageSize(Number(e.target.value));
            changeLimit && changeLimit(Number(e.target.value));
          }}
          className="form-select d-inline-block w-auto"
        >
          {sizePerPageList &&
            sizePerPageList.map((pageSize, index) => {
              return (
                <option key={index} value={pageSize.value}>
                  {pageSize.text}
                </option>
              );
            })}
        </select>

        <span className={'mx-3'}>{`Total: ${amount}`}</span>

        <span className="ms-auto mr-3">
          Page{' '}
          <strong>
            {currentPage} of {pageCount}
          </strong>
        </span>

        <div className="pagination pagination-rounded d-inline-flex mx-3">
          <button
            onClick={() => setCurrentPage && setCurrentPage(1)}
            className="page-link"
            disabled={currentPage === 1}
          >
            &#8676;
          </button>
          <button
            onClick={() => setCurrentPage && setCurrentPage(currentPage - 1)}
            className="page-link"
            disabled={currentPage === 1}
          >
            &#8672;
          </button>
          <button
            onClick={() => setCurrentPage && setCurrentPage(currentPage + 1)}
            className="page-link"
            disabled={currentPage === pageCount}
          >
            &#8674;
          </button>
          <button
            onClick={() => setCurrentPage && setCurrentPage(pageCount)}
            className="page-link"
            disabled={currentPage === pageCount}
          >
            &#8677;
          </button>
        </div>
      </div>
    </>
  );
};

export default Pagination;
