import moment from 'moment';
import { AxiosError } from 'axios';

export const toUTCDate = (date: Date) =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000);

export const toLocalDate = (date: string) => moment(date).local(true).toDate();

export const getCurrentUtcTime = () =>
  new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000);

export const downloadBlob = (blob: Blob, name: string) => {
  const blobHref = window.URL.createObjectURL(blob);
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', blobHref);
  downloadAnchorNode.setAttribute('download', name);
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const downloadJson = (
  // eslint-disable-next-line
  json: Record<string, any>,
  name = 'exported_json',
) => {
  const blob = new Blob([JSON.stringify(json)], {
    type: 'text/json;charset=utf-8',
  });
  downloadBlob(blob, `${name}.json`);
};

export const downloadCsv = (data: string, name = 'exported_csv') => {
  const blob = new Blob([data], {
    type: 'text/csv;charset=utf-8;',
  });
  downloadBlob(blob, `${name}.csv`);
};

export const findDuplicates = (values: string[], array: { id: string }[]) => {
  const duplicates: string[] = [];
  values.forEach((_p: string) => {
    const p = _p.toUpperCase();

    if (array?.some((ep) => ep.id === p)) {
      duplicates.push(p);
    }
  });
  return duplicates;
};

export const onlyUnique = (value: string, index: number, self: string[]) => {
  return self.indexOf(value) === index;
};

export const generateErrorMessage = (
  err: unknown,
  transformFunc?: ({
    status,
    message,
    details,
  }: {
    status: number;
    message: string;
    details: string;
  }) => string,
) => {
  type ObjectError = { message: string; details: string };
  type StringError = string;

  const error = err as AxiosError<ObjectError | StringError>;
  const status = error.response?.status || 500;
  const message =
    (error.response?.data as ObjectError)?.message ||
    (error.response?.data as StringError) ||
    '';
  const firstLetterUpMessage = message
    ? message[0].toUpperCase() + message.slice(1)
    : '';
  const details = (error.response?.data as ObjectError)?.details || '';

  if (!message && !details) return 'Unknown error';

  if (transformFunc) {
    return transformFunc({ status, message, details });
  }

  return `[${error.response?.status}] ${[firstLetterUpMessage, details]
    .filter(Boolean)
    .join(' — ')}`;
};

export const firstLetterUp = (str: string) =>
  str?.length ? str[0].toUpperCase() + str.slice(1) : '';

export const getFileExtension = (url: string) => {
  if (!url) return '';
  const splitUrl = url.split('.');
  if (splitUrl.length < 2) return '';
  const lastPart = splitUrl[splitUrl.length - 1];
  if (lastPart.length > 5) return '';

  return lastPart;
};
