import { createSelector } from 'reselect';
import { INFTState } from './types';
import { ItemMetaType, IItemMetaType } from 'constants/global';

export const selectNFTs = createSelector(
  (state: { nft: INFTState }) => state.nft,
  (nft) => nft.nfts,
);

export const selectNFTsOptions = createSelector(
  (state: { nft: INFTState }) => state.nft,
  (nft) =>
    nft.nfts &&
    nft.nfts.list.map((nft) => ({ value: nft.id, label: nft.name })),
);

export const selectNFT = createSelector(
  (state: { nft: INFTState }) => state.nft,
  (nft) => ({ nft: nft.nft, loading: nft.loading }),
);

export const selectNFTLoading = createSelector(
  (state: { nft: INFTState }) => state.nft,
  (nft) => nft.loading,
);

export const selectNftError = createSelector(
  (state: { nft: INFTState }) => state.nft,
  (nft) => nft.error,
);

export const selectTemplates = createSelector(
  (state: { nft: INFTState }) => state.nft,
  (nft) => ({ templates: nft.templates, loading: nft.loading }),
);

export const selectTemplatesUseCase = createSelector(
  (state: { nft: INFTState }) => state.nft,
  (nft) => ({
    templates: nft.templates?.filter((i) => i.type === ItemMetaType.USE_CASE),
    loading: nft.loading,
  }),
);

export const selectTemplatesTransparency = createSelector(
  (state: { nft: INFTState }) => state.nft,
  (nft) => ({
    templates: nft.templates?.filter(
      (i) => i.type === ItemMetaType.TRANSPARENCY,
    ),
    loading: nft.loading,
  }),
);

export const selectTemplatesBadges = createSelector(
  (state: { nft: INFTState }) => state.nft,
  (nft) => ({
    templates: nft.templates?.filter((i) => i.type === ItemMetaType.BADGES),
    loading: nft.loading,
  }),
);

export const selectTemplatesMetadata = createSelector(
  (state: { nft: INFTState }) => state.nft,
  (nft) => ({
    templates: nft.templates?.filter((i) => i.type === ItemMetaType.METADATA),
    loading: nft.loading,
  }),
);

const filterTemplateOptions = (nft: INFTState, type: IItemMetaType) => {
  const templates =
    nft?.templates &&
    nft.templates
      .filter(
        (template) =>
          nft?.nft?.useCases &&
          nft?.nft?.useCases.every(
            (useCase) => useCase.templateId !== template.id,
          ) &&
          template.type === type,
      )
      .map((t) => ({
        value: t.id,
        label: t.title,
      }));

  return templates;
};

export const selectTemplateOptionsUseCase = createSelector(
  (state: { nft: INFTState }) => state.nft,
  (nft) => {
    return filterTemplateOptions(nft, ItemMetaType.USE_CASE);
  },
);

export const selectTemplateOptionsTransparency = createSelector(
  (state: { nft: INFTState }) => state.nft,
  (nft) => {
    return filterTemplateOptions(nft, ItemMetaType.TRANSPARENCY);
  },
);

export const selectBadgeOptionsTransparency = createSelector(
  (state: { nft: INFTState }) => state.nft,
  (nft) => {
    return filterTemplateOptions(nft, ItemMetaType.BADGES);
  },
);

export const selectOptionsMetadata = createSelector(
  (state: { nft: INFTState }) => state.nft,
  (nft) => {
    return filterTemplateOptions(nft, ItemMetaType.METADATA);
  },
);

export const selectOrders = createSelector(
  (state: { nft: INFTState }) => state.nft,
  (nft) => ({ list: nft.orders?.list || [], amount: nft.orders?.amount || 0 }),
);

export const selectOwners = createSelector(
  (state: { nft: INFTState }) => state.nft,
  (nft) => nft.owners,
);

export const selectImported = createSelector(
  (state: { nft: INFTState }) => state.nft,
  (nft) => nft.imported,
);
