import { SideBarContentProps } from './types';
import { Link } from 'react-router-dom';
import AppMenu from '../Menu';
import profileImg from 'assets/images/users/default-avatar.jpeg';
import { useSelector } from 'react-redux';
import { selectMe } from 'redux/auth/selectors';

const SideBarContent = ({ hideUserProfile }: SideBarContentProps) => {
  const user = useSelector(selectMe);
  return (
    <>
      {!hideUserProfile && (
        <div className="leftbar-user">
          <Link to="/">
            <img
              src={profileImg}
              alt=""
              height="42"
              className="rounded-circle shadow-sm"
            />
            <span className="leftbar-user-name">{user?.email}</span>
          </Link>
        </div>
      )}

      <AppMenu />
    </>
  );
};

export default SideBarContent;
