import { Button } from 'react-bootstrap';
import { FormInputFile } from 'components/index';
import React from 'react';
import { FileUploaderProps } from './types';

const FileUploaderWithUrl = ({
  files = [],
  url,
  label,
  onUpload,
  onRemove,
  removeUrl,
  error,
  accept,
  className,
  placeholder,
}: FileUploaderProps) => {
  return (
    <div className={className}>
      <FormInputFile
        label={label}
        onUpload={onUpload}
        files={files || []}
        multiple={false}
        handleRemove={onRemove}
        error={error}
        accept={accept}
      />
      {!!url && (
        <div
          className={
            'd-flex align-items-center justify-content-end overflow-hidden'
          }
        >
          <span className={'mr-auto'}>
            {url.length > 20 ? `...${url.slice(-20)}` : url}
          </span>
          <a title={url} href={url} target={'_blank'} className={'d-flex ms-2'}>
            <i className={'mdi mdi-eye'} />
          </a>
          <Button variant="link" className="py-1" onClick={removeUrl}>
            <i className="mdi mdi-delete-outline text-danger"></i>
          </Button>
        </div>
      )}
      {!url && placeholder && (
        <div className={'d-flex align-items-center overflow-hidden '}>
          <span className={'mr-auto py-1'}>{placeholder}</span>
        </div>
      )}
    </div>
  );
};

export default FileUploaderWithUrl;
