import { INFT, ITemplate } from './types';

export const createNFTRequest = (nft: INFT) => {
  return Object.assign(
    {},
    { assetAddress: nft.assetAddress },
    { coverImageUrl: nft.coverImageUrl },
    { name: nft.name },
    { slug: nft.slug },
    { designerName: nft.designerName },
    { designerSlug: nft.designerSlug },
    { onlyByPromoCode: nft.onlyByPromoCode },
    { hideOnShopPage: nft.hideOnShopPage },
    { liveAt: nft.liveAt },
    { saleType: nft.saleType },
    { sort: nft.sort },
    nft.coverVideoUrl && { coverVideoUrl: nft.coverVideoUrl },
    nft.description && { description: nft.description },
    nft.euroPriceInCents && { euroPriceInCents: nft.euroPriceInCents },
    nft.glbUrl && { glbUrl: nft.glbUrl },
    (nft.imageUrls?.[0] || nft.imageUrls?.length) && {
      imageUrls: nft.imageUrls,
    },
    nft.fake3dImageUrls?.length && {
      fake3dImageUrls: nft.fake3dImageUrls,
    },
    nft.meta && { meta: nft.meta },
    nft.ogImageUrl && { ogImageUrl: nft.ogImageUrl },
    nft.story && { story: nft.story },
    nft.usdzUrl && { usdzUrl: nft.usdzUrl },
    nft.dropId && { dropId: nft.dropId },
  );
};

export const createTemplateRequest = (template: ITemplate) => {
  return {
    title: template.title,
    icon: template.icon,
    productPageText: template.productPageText,
    image: template.image,
    text: template.text,
    ctaLabel: template.ctaLabel,
    ctaLink: template.ctaLink,
  };
};
