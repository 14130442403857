import MenuItemLink from '../MenuItemLink';
import { IMenuItemProps } from './types';
import classNames from 'classnames';

const MenuItem = ({ item, className, linkClassName }: IMenuItemProps) => {
  return (
    <li className={classNames('side-nav-item', className)}>
      <MenuItemLink item={item} className={linkClassName} />
    </li>
  );
};

export default MenuItem;
