import React, { useEffect, Suspense } from 'react';

const DefaultLayout = ({ children }: { children: JSX.Element }) => {
  useEffect(() => {
    if (document.body) document.body.classList.add('authentication-bg');

    return () => {
      if (document.body) document.body.classList.remove('authentication-bg');
    };
  }, []);

  return (
    <>
      <Suspense fallback={false}>{children}</Suspense>
    </>
  );
};
export default DefaultLayout;
