import classNames from 'classnames';
import { SpinnerProps } from './types';

const Spinner = ({
  color = 'secondary',
  size,
  type = 'bordered',
  children,
  className,
  fullscreen = true,
}: SpinnerProps) => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ height: '100%', minHeight: fullscreen ? '100vh' : undefined }}
    >
      <div
        role="status"
        className={classNames(
          {
            'spinner-border': type === 'bordered',
            'spinner-grow': type === 'grow',
          },
          [`text-${color}`],
          { [`avatar-${size}|`]: size },
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Spinner;
