import { call, put } from 'redux-saga/effects';
import {
  getUserFailure,
  getUsersFailure,
  getUsersSuccess,
  getUserSuccess,
} from './reducer';
import { PayloadAction } from '@reduxjs/toolkit';
import { generateErrorMessage } from 'utils';
import config from 'api/config';
import apiAuth from 'api/middlewareAuth';

export function* getUsersSaga() {
  try {
    const { data } = yield call(apiAuth, config.users);
    yield put(getUsersSuccess(data));
  } catch (e) {
    yield put(getUsersFailure(generateErrorMessage(e)));
  }
}

export function* getUserSaga({ payload }: PayloadAction<string>) {
  try {
    const { data } = yield call(apiAuth, config.user(payload));

    yield put(getUserSuccess(data));
  } catch (e) {
    yield put(getUserFailure(generateErrorMessage(e)));
  }
}
