import { createSelector } from 'reselect';
import { ISmartContractTemplate, ISmartContract, IBlockchain } from './types';

export const selectBlockChain = createSelector(
  (state: { blockchain: IBlockchain }) => state.blockchain,
  (blockchain) => blockchain.blockchain,
);

export const selectSmartContract = createSelector(
  (state: { blockchain: IBlockchain }) => state.blockchain,
  (blockchain) => blockchain.smartContract,
);
export const selectSmartContracts = createSelector(
  (state: { blockchain: IBlockchain }) => state.blockchain,
  (blockchain) => ({
    list: blockchain.smartContracts,
    amount: blockchain.smartContractsAmount,
  }),
);
export const selectSmartContractTemplate = createSelector(
  (state: { blockchain: IBlockchain }) => state.blockchain,
  (blockchain) => blockchain.template,
);
export const selectSmartContractTemplates = createSelector(
  (state: { blockchain: IBlockchain }) => state.blockchain,
  (blockchain) => blockchain.templates,
);

export const selectFetchError = createSelector(
  (state: { blockchain: IBlockchain }) => state.blockchain,
  (blockchain) => ({
    loading: blockchain.loading,
    error: blockchain.error,
    fetching: blockchain.fetching,
  }),
);

export const selectTokens = createSelector(
  (state: { blockchain: IBlockchain }) => state.blockchain,
  (blockchain) => ({
    tokens: blockchain.tokens,
    tokensAmount: blockchain.tokensAmount,
  }),
);

export const selectBlockchains = createSelector(
  (state: { blockchain: IBlockchain }) => state.blockchain,
  (blockchain) => blockchain.blockchains,
);
