import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileDropdown from '../ProfileDropdown';
// import TopbarSearch from './TopbarSearch';

import profilePic from '../../assets/images/users/default-avatar.jpeg';
import { meRequest } from 'redux/auth/reducer';
import { selectMe } from 'redux/auth/selectors';
import { generatePath, Link } from 'react-router-dom';

import Logo from '../../assets/images/logo.png';
import { ITopbarProps } from './types';
import routes from 'navigation/paths';

const Topbar = ({ toggleSidebar }: ITopbarProps) => {
  const dispatch = useDispatch();

  const user = useSelector(selectMe);

  useEffect(() => {
    if (!user) dispatch(meRequest());
  }, [dispatch, user]);

  const profilesMenu = [
    {
      label: 'Users',
      icon: 'uil-users-alt',
      redirectTo: user ? routes.users : routes.home,
    },
    {
      label: 'My Account',
      icon: 'mdi mdi-account-circle',
      redirectTo: user
        ? generatePath(routes.user, { userId: user?.id })
        : routes.home,
    },
    {
      label: 'Logout',
      icon: 'mdi mdi-logout',
      redirectTo: routes.logout,
    },
  ];
  return (
    <React.Fragment>
      <div className="navbar-custom topnav-navbar topnav-navbar-light">
        <div className="container-fluid d-flex align-items-center">
          <Link to="/" className="topnav-logo">
            <img src={Logo} alt="logo" height="16" />
          </Link>
          <ul className="list-unstyled topbar-menu mb-0 ms-auto">
            <li className="dropdown notification-list">
              <ProfileDropdown
                profilePic={profilePic}
                menuItems={profilesMenu}
                username={user?.email || ''}
                userTitle={user?.role}
              />
            </li>
          </ul>
          <Link
            to="#"
            className="button-menu-mobile disable-btn"
            onClick={toggleSidebar}
          >
            <div className="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Link>
          {/* <TopbarSearch items={[]} /> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Topbar;
