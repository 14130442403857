import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IDrops,
  ApiDrop,
  IDrop,
  ApiDropUpdate,
  ApiAddNftsToDrip,
  ExportedDrop,
} from './types';

const initialState: IDrops = {
  drops: [],
  amount: 0,
  error: '',
  loading: false,
  drop: null,
};

const slice = createSlice({
  name: 'drops',
  initialState,
  reducers: {
    getDropsRequest: (state, i) => {
      state.loading = true;
    },
    getDropsSuccess: (
      state,
      { payload }: PayloadAction<{ list: IDrop[]; amount: number }>,
    ) => {
      state.drops = payload.list;
      state.amount = payload.amount;
      state.loading = false;
    },
    getDropsFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    createDropRequest: (state, i: PayloadAction<ApiDrop>) => {
      state.loading = true;
    },
    getDropRequest: (state, i: PayloadAction<string>) => {
      state.loading = true;
    },
    getDropSuccess: (
      state,
      { payload: { drop } }: PayloadAction<{ drop: IDrop | null }>,
    ) => {
      state.drop = drop;
      state.loading = false;
    },
    deleteDropRequest: (state, i: PayloadAction<string>) => state,
    updateDropRequest: (state, i: PayloadAction<ApiDropUpdate>) => state,
    addNftsToDropRequest: (state, i: PayloadAction<ApiAddNftsToDrip>) => state,
    importDropRequest: (state, i: PayloadAction<ExportedDrop>) => {
      state.loading = true;
    },
    importDropSuccess: (state) => {
      state.loading = false;
    },
    importDropFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const {
  getDropsRequest,
  createDropRequest,
  getDropsSuccess,
  getDropsFailure,
  getDropRequest,
  getDropSuccess,
  deleteDropRequest,
  updateDropRequest,
  addNftsToDropRequest,
  importDropRequest,
  importDropSuccess,
  importDropFailure,
} = slice.actions;
export const { reducer } = slice;
