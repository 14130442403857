import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { ProfileDropdownProps } from './types';

const ProfileDropdown = (props: ProfileDropdownProps) => {
  // const profilePic = props.profilePic || null;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  /*
   * toggle profile-dropdown
   */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
      <Dropdown.Toggle
        variant="link"
        id="dropdown-profile"
        as={Link}
        to="#"
        onClick={toggleDropdown}
        className="nav-link dropdown-toggle me-0 p-2" // nav-user arrow-none
      >
        {/* <span className="account-user-avatar">
          <img
            src={profilePic?.toString()}
            className="rounded-circle"
            alt="user"
          />
        </span> */}
        <i className="mdi mdi-account-circle me-2 text-dark"></i>
        <span>
          <span className="account-user-name text-dark">{props.username}</span>
          <span className="account-position text-dark">{props.userTitle}</span>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
        <div onClick={toggleDropdown}>
          {props.menuItems.map((item, i) => {
            return (
              <Link
                to={item.redirectTo}
                className="dropdown-item notify-item"
                key={i + '-profile-menu'}
              >
                <i className={`${item.icon} me-1`}></i>
                <span>{item.label}</span>
              </Link>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
