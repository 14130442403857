import { Card } from 'react-bootstrap';
import { INFTCardProps } from './types';
import classNames from 'classnames';
import moment from 'moment';
import styles from './styles.module.scss';
import { generatePath, useHistory } from 'react-router';
import routes from 'navigation/paths';
import { salesTypeTitle } from 'redux/nft/constants';
import { SaleTypes } from 'redux/nft/types';

const NFTCard = ({ nft }: INFTCardProps) => {
  const history = useHistory();
  const handleSelect = () => {
    history.push(generatePath(routes.nftsDetails, { nftId: nft.id }));
  };

  if (!nft) return null;

  return (
    <Card className={`d-block ${styles.container}`} onClick={handleSelect}>
      <div className={styles.cardImgBlock}>
        {nft?.coverImageUrl ? (
          <>
            <img
              className={`card-img-top ${styles.img}`}
              src={nft.coverImageUrl}
              alt=""
            />

            <div className="card-img-overlay">
              <div
                className={classNames(
                  'badge',
                  {
                    'bg-secondary': nft.isSoldOut,
                  },
                  'p-1',
                )}
              >
                {nft.isSoldOut && `Sold Out`}
              </div>
            </div>
          </>
        ) : (
          <div className={styles.imgPlaceholder}>
            <div className="m-auto">No cover image 🤓</div>
          </div>
        )}
      </div>

      <Card.Body
        className={
          nft.coverImageUrl ? 'position-relative py-2' : 'mt-auto py-2'
        }
      >
        <p className="text-body text-truncate py-1 m-0">{nft.designerName}</p>
        <h4 className="mt-0 mb-2 text-title h5">
          <span className="d-block text-truncate text-dark">{nft.name}</span>
        </h4>
        <div className="mb-1 h4">
          <span
            className={`badge ${
              nft.saleType === SaleTypes.AUCTION ? 'bg-success' : ' bg-dark'
            }`}
          >
            {salesTypeTitle[nft.saleType]}
          </span>
          {nft.hideOnShopPage && (
            <span className="ms-1 badge bg-danger">Hidden</span>
          )}
        </div>
        <span className="my-1 text-nowrap d-inline-block text-dark">
          Price:
          <b className="ms-1">{nft.euroPriceInCents / 100}</b>
          <i className="mdi mdi-currency-eur"></i>
        </span>
        <p className="m-0 text-nowrap text-body">
          <span className="text-dark me-1">Live at:</span>
          <span className="text-nowrap d-inline-block">
            {moment(nft.liveAt).format('DD.MM.yyyy HH:mm')}
          </span>
        </p>
      </Card.Body>
    </Card>
  );
};

export default NFTCard;
