/* eslint-disable react/display-name */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
import ReactMarkdown from 'react-markdown';

interface Props {
  markdown: string;
}

const Markdownify: React.FC<Props> = ({ markdown }) => (
  <ReactMarkdown
    components={{
      p: ({ ...props }) => <p className="p-0 mb-1" {...props} />,
      a: ({ ...props }) => <a target="_blank" rel="noreferrer" {...props} />,
      h2: ({ ...props }) => (
        <h2 className="h5 mb-2 text-secondary" {...props} />
      ),
    }}
  >
    {markdown}
  </ReactMarkdown>
);

export default Markdownify;
