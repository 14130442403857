import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styles from './styles.module.scss';
import { SortableItemProps } from './types';

export function SortableItem({ id, renderItem }: SortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={styles.draggable}
      {...attributes}
      {...listeners}
    >
      {renderItem !== undefined ? renderItem(id) : <span>{id}</span>}
    </div>
  );
}
