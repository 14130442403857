import { Fragment, useEffect, useState } from 'react';
import { ISidebarMenuProps } from './types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import MenuItem from '../MenuItem';
import { IMenuItem } from 'components/LeftSidebar/types';

const MenuItemWithChildren = ({
  item,
  linkClassName,
  subMenuClassNames,
  activeMenuItems,
  toggleMenu,
}: ISidebarMenuProps) => {
  const [open, setOpen] = useState(activeMenuItems.includes(item.key));

  useEffect(() => {
    setOpen(activeMenuItems.includes(item.key));
  }, [activeMenuItems, item]);

  const toggleMenuItem = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    const status = !open;
    setOpen(status);
    if (toggleMenu) toggleMenu(item, status);
    return false;
  };

  return (
    <li className={classNames('side-nav-item', { 'menuitem-active': open })}>
      <Link
        to="/#"
        onClick={toggleMenuItem}
        data-menu-key={item.key}
        aria-expanded={open}
        className={classNames('has-arrow', 'side-sub-nav-link', linkClassName, {
          'menuitem-active': activeMenuItems.includes(item.key) ? 'active' : '',
        })}
      >
        {item.icon && <i className={item.icon}></i>}
        {!item.badge ? (
          <span className="menu-arrow"></span>
        ) : (
          <span className={`badge bg-${item.badge.variant} float-end`}>
            {item.badge.text}
          </span>
        )}
        <span> {item.label} </span>
      </Link>
      <Collapse in={open}>
        <ul className={classNames(subMenuClassNames)}>
          {item.children &&
            item.children.map((child: IMenuItem, i: number) => {
              return (
                <Fragment key={i}>
                  {child.children ? (
                    <>
                      {/* parent */}
                      <MenuItemWithChildren
                        item={child}
                        linkClassName={
                          activeMenuItems.includes(child.key) ? 'active' : ''
                        }
                        activeMenuItems={activeMenuItems}
                        subMenuClassNames="side-nav-third-level"
                        toggleMenu={toggleMenu}
                      />
                    </>
                  ) : (
                    <>
                      {/* child */}
                      <MenuItem
                        item={child}
                        className={
                          activeMenuItems.includes(child.key)
                            ? 'menuitem-active'
                            : ''
                        }
                        linkClassName={
                          activeMenuItems.includes(child.key) ? 'active' : ''
                        }
                      />
                    </>
                  )}
                </Fragment>
              );
            })}
        </ul>
      </Collapse>
    </li>
  );
};

export default MenuItemWithChildren;
