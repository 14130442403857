import axios, { AxiosRequestConfig } from 'axios';
import { Token, TokenKeycloak } from './types';
import {
  getToken,
  refreshToken,
  getTokenKeycloak,
  refreshTokenKeycloak,
} from './helpers';
import config from './config';
import { ADMIN_TOKEN_KEYCLOAK } from 'api/helpers';

const api = () => {
  const instance = axios.create({
    baseURL: config.env.apiBaseURL,
    headers: { 'Content-Type': 'application/json' },
  });

  if (!config.env.useKeycloak) {
    instance.interceptors.request.use((config: AxiosRequestConfig) => {
      const token: Token = getToken();
      if (token && config.headers !== undefined) {
        config.headers['Authorization'] = 'Bearer ' + token.token;
      }
      return config;
    });

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        const originalRequest = error.config;
        const token = getToken();
        if (error.response.status === 401 && !originalRequest._retry && token) {
          originalRequest._retry = true;
          return refreshToken(token.refreshToken).then((response) => {
            originalRequest.headers['Authorization'] =
              'Bearer ' + token.refreshToken;
            return axios(originalRequest);
          });
        }

        return Promise.reject(error);
      },
    );

    return instance;
  } else {
    instance.interceptors.request.use((config: AxiosRequestConfig) => {
      const token: TokenKeycloak = getTokenKeycloak();
      if (token && config.headers !== undefined) {
        config.headers['Authorization'] = 'Bearer ' + token.access_token;
      }
      return config;
    });

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        const originalRequest = error.config;
        const token = getTokenKeycloak();
        if (error.response.status === 401 && !originalRequest._retry && token) {
          originalRequest._retry = true;
          return refreshTokenKeycloak(token.refresh_token).then((response) => {
            const token = getTokenKeycloak();
            originalRequest.headers['Authorization'] =
              'Bearer ' + token.access_token;
            return axios(originalRequest);
          });
        }
        //localStorage.removeItem(ADMIN_TOKEN_KEYCLOAK);

        return Promise.reject(error);
      },
    );

    return instance;
  }
};

export default api();
