import React from 'react';
import Routes from 'navigation';
// For Saas import Saas.scss
import './assets/scss/Saas.scss';
import moment from 'moment-timezone';

function App() {
  moment.tz.setDefault('UTC');
  return <Routes />;
}

export default App;
