/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createUseCaseTemplateFailure,
  createUseCaseTemplateSuccess,
  deleteTemplateFailure,
  deleteTemplateSuccess,
  editUseCaseFailure,
  editUseCaseSuccess,
  getTemplatesRequest,
  importNftFailure,
  importNftSuccess,
  showEarlyAccessLinkFailure,
  showEarlyAccessLinkSuccess,
  showShopPageFailure,
  showShopPageSuccess,
  updateTemplateFailure,
  updateTemplateSuccess,
} from 'redux/nft/reducer';
import { call, put, select, all } from 'redux-saga/effects';
import {
  INFTPayload,
  INFT,
  ITemplate,
  INFTsData,
  IImportNFTData,
  INFTPayloadStepFirst,
  SaleTypes,
  UpdateUsecaseRequest,
  GetOrdersApi,
} from './types';

import {
  getNFTByIdSuccess,
  getNFTsFailure,
  getNFTsSuccess,
  getNFTByIdFailure,
  createNftFailure,
  createNftSuccess,
  updateNftSuccess,
  updateNftFailure,
  deleteNFTByIdSuccess,
  deleteNFTByIdFailure,
  getTemplatesSuccess,
  getTemplatesFailure,
  createUseCaseSuccess,
  createUseCaseFailure,
  getNFTByIdRequest,
  deleteUseCaseSuccess,
  deleteUseCaseFailure,
  getNftOrdersSuccess,
  getNftOrdersFailure,
  getOwnersSuccess,
  getOwnersFailure,
  getAuctionNftsFailure,
  getAuctionNftsSuccess,
} from './reducer';
import { PayloadAction } from '@reduxjs/toolkit';
import { history } from 'navigation';
import routes from 'navigation/paths';
import { generatePath } from 'react-router';
import { selectNFTs } from './selectors';
import { createNFTRequest, createTemplateRequest } from './utils';
import { toast } from 'react-toastify';
import { OrderStatus, toastSettings } from 'constants/global';
import { omit } from 'lodash';
import { excludeFields } from './constants';
import { UploadFilesTypes } from 'constants/global';
import { ApiUpdateAuctionRequest } from '../auction/types';
import { generateErrorMessage } from 'utils';
import {
  CreateUsecaseRequest,
  CreateUCTRequest,
  UpdateUCTRequest,
} from './types';
import { selectNFT } from 'redux/nft/selectors';
import api from 'api/middleware';
import config, { ENVIRONMENT } from 'api/config';

export function* getNFTsSaga({
  payload: { limit, offset, filter, search, lazy = true },
}: PayloadAction<{
  limit?: number;
  offset?: number;
  filter?: Record<string, string>;
  search?: string;
  lazy?: boolean;
}>) {
  try {
    const nftsData: INFTsData = yield select(selectNFTs);
    const {
      data: { list, amount },
    } = yield call(api, config.nfts, {
      params: { limit, offset, search, ...filter },
    });

    const _list = list.map((n: INFT) => ({
      ...n,
      meta: n.meta ? JSON.parse(n.meta as string) : {},
    }));

    yield put(
      getNFTsSuccess({
        amount,
        list: lazy ? [...(nftsData?.list || []), ..._list] : _list,
      }),
    );
  } catch (e) {
    yield put(getNFTsFailure(generateErrorMessage(e)));
  }
}

export function* getNFTByIdSaga({
  payload: { nftId },
}: PayloadAction<{ nftId: string }>) {
  try {
    const {
      data: { meta, ...nft },
    } = yield call(api, config.nftById(nftId));
    const _meta = meta ? JSON.parse(meta) : null;
    yield put(getNFTByIdSuccess({ ...nft, meta: _meta }));
  } catch (e) {
    yield put(getNFTByIdFailure(generateErrorMessage(e)));
  }
}

export function* createNFTSaga({
  payload,
}: PayloadAction<INFTPayloadStepFirst>) {
  try {
    const { data } = yield call(api.post, config.nfts, payload);
    yield put(getNFTByIdSuccess(data));
    yield put(createNftSuccess());
    history.push(generatePath(routes.createNft, { nftId: data.id, step: '2' }));
  } catch (e) {
    yield put(createNftFailure(generateErrorMessage(e)));
  }
}

export function* updateNFTSaga({
  payload: { id, uuid, body, auction, redirect = true, step },
}: PayloadAction<{
  uuid?: string;
  id: string;
  body: INFTPayload;
  redirect?: boolean;
  auction: ApiUpdateAuctionRequest | null;
  step?: string;
}>) {
  try {
    const { files, ...rest } = body;
    const newUrls: { [key: string]: string | string[] } = {};
    let metaFilesUrls: string[] = [];
    let filesForFrees: string[] = [];
    if (uuid) {
      // files upload
      const filesReq = [];
      const filesKeys = [];
      for (const key in files) {
        const fd = new FormData();
        fd.append('uuid', uuid);
        fd.append('type', key);
        if (
          key === UploadFilesTypes.ADDITIONAL_FILES ||
          key === UploadFilesTypes.FAKE_3D_IMAGES ||
          key === UploadFilesTypes.IMAGE_URLS
        ) {
          fd.append('notDeleteOld', 'true');
        }
        files[key].forEach((f) => fd.append('files', f));
        filesReq.push(
          call(api.post, config.nftFiles, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }),
        );
        filesKeys.push(key);
      }

      const urls: { data: { urls: string[] } }[] = yield all(filesReq);
      filesKeys.forEach((key, i) => {
        if (key === UploadFilesTypes.IMAGE_URLS) {
          newUrls.imageUrls = [...(rest.imageUrls || []), ...urls[i].data.urls];
        } else if (key === UploadFilesTypes.FAKE_3D_IMAGES) {
          newUrls.fake3dImageUrls = [
            ...(rest.fake3dImageUrls || []),
            ...urls[i].data.urls,
          ];
        } else if (key === UploadFilesTypes.ADDITIONAL_FILES) {
          metaFilesUrls = urls[i].data.urls;
        } else if (key === UploadFilesTypes.FILE_URLS_FOR_FREEZE) {
          filesForFrees = urls[i].data.urls;
        } else {
          newUrls[key] = urls[i].data.urls[0];
        }
      });
    }
    const _additionalFilesUrls = metaFilesUrls.concat(
      rest.meta?.additionalFilesUrls || [],
    );
    const _fileUrlsForFreeze = filesForFrees.concat(
      rest.meta?.fileUrlsForFreeze || [],
    );
    const { data: _nft } = yield call(api.patch, config.nftById(id), {
      ...rest,
      ...newUrls,
      meta: JSON.stringify({
        ...rest.meta,
        additionalFilesUrls: _additionalFilesUrls,
        fileUrlsForFreeze: _fileUrlsForFreeze,
      }),
    });
    yield put(updateNftSuccess(_nft));

    if (auction !== null) {
      const { id: auctionId, ...auctionBody } = auction;

      if (rest.saleType === SaleTypes.AUCTION && auctionId) {
        yield call(api.patch, config.auctionById(auctionId), auctionBody);
      }
      if (rest.saleType === SaleTypes.AUCTION && !auctionId) {
        yield call(api.post, config.auction, auctionBody);
      }
    }

    if (step) {
      history.push(generatePath(routes.createNft, { nftId: id, step }));
    }
    if (redirect) history.push(generatePath(routes.nftsDetails, { nftId: id }));
  } catch (e) {
    yield put(updateNftFailure(generateErrorMessage(e)));
  }
}

export function* deleteNFTByIdSaga({ payload }: PayloadAction<number>) {
  try {
    const { data } = yield call(api.delete, config.nftById(String(payload)));
    yield put(deleteNFTByIdSuccess(data));
    yield toast.success('NFT has been deleted successfully!', toastSettings);
  } catch (e) {
    yield put(deleteNFTByIdFailure(generateErrorMessage(e)));
  }
}

export function* getTemplatesSaga() {
  try {
    const { data } = yield call(api, config.templates);
    yield put(getTemplatesSuccess(data));
  } catch (e) {
    yield put(getTemplatesFailure(generateErrorMessage(e)));
  }
}

export function* createUseCaseSaga({
  payload: { itemId, body },
}: PayloadAction<{ itemId: string; body: CreateUsecaseRequest }>) {
  try {
    yield call(api.post, config.templateItems(itemId), body);
    yield put(createUseCaseSuccess());
    yield put(getNFTByIdRequest({ nftId: itemId }));
  } catch (e) {
    yield put(createUseCaseFailure(generateErrorMessage(e)));
  }
}

export function* deleteUseCaseSaga({
  payload: { nftId, ucId },
}: PayloadAction<{ nftId: string; ucId: string }>) {
  try {
    yield call(api.delete, config.templateItem(nftId, ucId));
    yield put(deleteUseCaseSuccess());
    yield put(getNFTByIdRequest({ nftId }));
    yield put(getTemplatesRequest());
  } catch (e) {
    yield put(deleteUseCaseFailure(generateErrorMessage(e)));
  }
}

export function* getNftOrdersSaga({
  payload: { nftId, limit, offset, contractId, statuses },
}: PayloadAction<GetOrdersApi>) {
  try {
    const { data } = yield call(api, config.orders, {
      params: {
        itemIds: nftId ? [nftId] : undefined,
        limit,
        offset,
        contractId,
        statuses,
      },
    });
    yield put(getNftOrdersSuccess(data));
  } catch (e) {
    yield put(getNftOrdersFailure(generateErrorMessage(e)));
  }
}

export function* getOwnersSaga({
  payload: { nftId, limit, offset },
}: PayloadAction<{ nftId: string; limit: number; offset: number }>) {
  try {
    const { data } = yield call(api, config.customers, {
      params: {
        itemIds: [Number(nftId)],
        orderStatuses: [OrderStatus.FULFILLED],
        limit,
        offset,
      },
    });
    yield put(getOwnersSuccess(data));
  } catch (e) {
    yield put(getOwnersFailure(generateErrorMessage(e)));
  }
}

export function* getAuctionNftsSaga() {
  try {
    const { data } = yield call(api, config.auctionNfts);
    yield put(getAuctionNftsSuccess(data));
  } catch (e) {
    yield put(getAuctionNftsFailure(generateErrorMessage(e)));
  }
}

export function* importNftSaga({ payload }: PayloadAction<IImportNFTData>) {
  try {
    // Create NFT
    const nftRequest: { meta: string } & INFT = yield createNFTRequest(payload);

    const {
      data: { id: nftId },
    } = yield call(api.post, config.nfts, nftRequest);
    // Check is set templates
    if (payload.templates) {
      // Get Templates
      const { data: templates } = yield call(api, config.templates);
      for (const template of payload.templates) {
        const isSet = templates.some(
          (t: ITemplate) => t.title === template.title,
        );
        if (!isSet) {
          // Create template
          const templateRequest = createTemplateRequest(template);
          yield call(api.post, config.templates, templateRequest);
        }
      }
    }
    //Create use cases
    if (payload.useCases && Boolean(payload.useCases?.length)) {
      for (const useCase of payload.useCases) {
        const body = omit(useCase, excludeFields);
        yield call(api.post, config.templateItems(nftId.toString()), body);
      }
    }
    // Create auction
    if (payload.auction) {
      const { startDate, endDate, minStep } = payload.auction;
      const auctionRequest = { startDate, endDate, itemId: nftId, minStep };
      yield call(api.post, config.auction, auctionRequest);
    }
    if (payload.promoCodes?.length) {
      yield call(api.post, config.promoCodes, {
        itemId: nftId,
        promoCodes: payload?.promoCodes.map((p) => p.id),
      });
    }

    yield put(importNftSuccess());
    yield toast.success('Import was successfully!', toastSettings);
  } catch (e) {
    yield put(importNftFailure(generateErrorMessage(e)));
  }
}

function* uploadIcon(body: CreateUCTRequest) {
  let icon = body.icon;
  if (body.file) {
    const { nft }: { nft: INFT } = yield select(selectNFT);
    const fd = new FormData();
    fd.append('uuid', nft.uuid);
    fd.append('type', `transparencyIcon${body.title}`);
    fd.append('files', body.file);
    const { data } = yield call(api.post, config.nftFiles, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    icon = data.urls[0];
  }
  return icon;
}

export function* createUseCaseTemplateSaga({
  payload: { itemId, body },
}: PayloadAction<{ itemId: string; body: CreateUCTRequest }>) {
  try {
    const { file, ...rest } = body;
    const icon: string = yield call(uploadIcon, body);
    yield call(api.post, config.templates, { ...rest, icon });
    yield put(getTemplatesRequest());

    yield put(createUseCaseTemplateSuccess());
    yield put(getNFTByIdRequest({ nftId: itemId }));
  } catch (e) {
    yield put(createUseCaseTemplateFailure(generateErrorMessage(e)));
  }
}

export function* editUseCaseSaga({
  payload: { itemId, ucId, body },
}: PayloadAction<{
  itemId: string;
  ucId: string;
  body: UpdateUsecaseRequest;
}>) {
  try {
    const { file, ...rest } = body;
    const icon: string = yield call(uploadIcon, body);
    yield call(api.patch, config.templateItem(itemId, ucId), {
      ...rest,
      icon,
    });
    yield put(editUseCaseSuccess());
    yield put(getNFTByIdRequest({ nftId: itemId }));
  } catch (e) {
    yield put(editUseCaseFailure(generateErrorMessage(e)));
  }
}

export function* deleteUCTSaga({
  payload: { templateId },
}: PayloadAction<{ templateId: string }>) {
  try {
    yield call(api.delete, config.templateById(templateId));
    yield put(deleteTemplateSuccess());
    yield put(getTemplatesRequest());
  } catch (e) {
    yield put(deleteTemplateFailure(generateErrorMessage(e)));
  }
}

export function* updateTemplateSaga({
  payload: { templateId, body },
}: PayloadAction<{ templateId: string; body: UpdateUCTRequest }>) {
  try {
    const { file, ...rest } = body;
    const icon: string = yield call(uploadIcon, body);
    yield call(api.patch, config.templateById(templateId), {
      ...rest,
      icon,
    });
    yield put(updateTemplateSuccess());
    yield put(getTemplatesRequest());
  } catch (e) {
    yield put(updateTemplateFailure(generateErrorMessage(e)));
  }
}

export function* showShopPageSaga({
  payload: { date },
}: PayloadAction<{ date: Date }>) {
  try {
    const {
      data: { hash },
    } = yield call(api.post, config.encrypt, {
      data: String(date.getTime()),
    });
    yield put(showShopPageSuccess());
    window.open(
      `${ENVIRONMENT.frontendBaseUrl}/preview/shop?dateHash=${hash}`,
      '_blank',
    );
  } catch (e) {
    yield put(showShopPageFailure(generateErrorMessage(e)));
  }
}

export function* showEarlyAccessLinkSaga({
  payload: { date, snapshotId, slug },
}: PayloadAction<{ date: Date; snapshotId: number; slug: string }>) {
  try {
    const {
      data: { hash: dateHash },
    } = yield call(api.post, config.encrypt, {
      data: String(date.getTime()),
    });
    const {
      data: { hash },
    } = yield call(api.post, config.encrypt, {
      data: JSON.stringify({
        filter: { slug },
        snapshotId,
        dateHash,
      }),
    });

    yield put(showEarlyAccessLinkSuccess());
    window.open(
      `${ENVIRONMENT.frontendBaseUrl}/preview/access?paramsEnc=${hash}`,
      '_blank',
    );
  } catch (e) {
    yield put(showEarlyAccessLinkFailure(generateErrorMessage(e)));
  }
}
