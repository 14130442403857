import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import {
  CreatePromoCodesRequest,
  DeletePromoCodesRequest,
  GetPromoCodesRequest,
  UpdatePromoCodeRequest,
} from './types';
import {
  createPromoCodesFailure,
  createPromoCodesSuccess,
  deletePromoCodesFailure,
  deletePromoCodesSuccess,
  getPromoCodesFailure,
  getPromoCodesSuccess,
  updatePromoCodeFailure,
  updatePromoCodeSuccess,
} from './reducer';
import { generateErrorMessage } from 'utils';
import api from 'api/middleware';
import config from 'api/config';

export function* getPromoCodesSaga({
  payload,
}: PayloadAction<GetPromoCodesRequest>) {
  try {
    const { data } = yield call(api, config.promoCodes, {
      params: {
        limit: payload.limit,
        offset: payload.offset,
        ...payload.filter,
      },
    });

    yield put(getPromoCodesSuccess(data));
  } catch (e) {
    yield put(getPromoCodesFailure(generateErrorMessage(e)));
  }
}

export function* createPromoCodesSaga({
  payload: { loadAfter, ...payload },
}: PayloadAction<
  CreatePromoCodesRequest & { loadAfter?: Partial<GetPromoCodesRequest> }
>) {
  try {
    const { data } = yield call(api.post, config.promoCodes, payload);

    if (loadAfter) {
      yield call(
        getPromoCodesSaga.bind(null, {
          type: '',
          payload: {
            limit: loadAfter.limit,
            offset: loadAfter.offset,
            filter: { itemId: String(payload.itemId) },
          },
        }),
      );
    }

    yield put(createPromoCodesSuccess(data));
  } catch (e) {
    yield put(createPromoCodesFailure(generateErrorMessage(e)));
  }
}

export function* updatePromoCodeSaga({
  payload: { loadAfter, ...payload },
}: PayloadAction<
  UpdatePromoCodeRequest & { loadAfter?: Partial<GetPromoCodesRequest> }
>) {
  try {
    yield call(api.patch, config.promoCodes, payload);

    if (loadAfter) {
      yield call(
        getPromoCodesSaga.bind(null, {
          type: '',
          payload: {
            limit: loadAfter.limit,
            offset: loadAfter.offset,
            filter: loadAfter.filter,
          },
        }),
      );
    }
    yield put(updatePromoCodeSuccess());
  } catch (e) {
    yield put(updatePromoCodeFailure(generateErrorMessage(e)));
  }
}

export function* deletePromoCodesSaga({
  payload: { loadAfter, ...payload },
}: PayloadAction<
  DeletePromoCodesRequest & { loadAfter?: Partial<GetPromoCodesRequest> }
>) {
  try {
    yield call(api.delete, config.promoCodes, { data: payload });

    if (loadAfter) {
      yield call(
        getPromoCodesSaga.bind(null, {
          type: '',
          payload: {
            limit: loadAfter.limit,
            offset: loadAfter.offset,
            filter: loadAfter.filter,
          },
        }),
      );
    }
    yield put(deletePromoCodesSuccess());
  } catch (e) {
    yield put(deletePromoCodesFailure(generateErrorMessage(e)));
  }
}
