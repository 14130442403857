import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Form, Button } from 'react-bootstrap';
import { FileUploaderProps } from './types';
import styles from './styles.module.scss';

const FileUploader = ({
  label = '',
  multiple = false,
  files,
  onUpload,
  placeholder = `Click or drag'n'drop file${multiple ? 's' : ''} here`,
  showFileName = true,
  handleRemove,
  error,
  accept,
}: FileUploaderProps) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onUpload(multiple ? [...files, ...acceptedFiles] : acceptedFiles);
    },
    [files, multiple],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept,
  });

  return (
    <Form.Group className={'mb-2'}>
      <Form.Label>{label}</Form.Label>
      <div
        {...getRootProps({
          className: `form-control ${styles.dropZone} ${
            isDragActive && styles.dropZoneActive
          } ${error && 'border border-danger'}`,
        })}
      >
        <input className={styles.input} {...getInputProps()} />
        {Boolean(files.length && showFileName) ? (
          files.map((f) => (
            <div key={f.name} className={'d-flex align-items-center w-100'}>
              <span className={'flex-grow-1'}>
                {f.name.length > 25 ? `...${f.name.slice(-25)}` : f.name}
              </span>
              {Boolean(handleRemove) && (
                <Button
                  className="ms-auto"
                  variant="link"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemove && handleRemove(f.name);
                  }}
                >
                  <i className="mdi mdi-delete-outline text-danger"></i>
                </Button>
              )}
            </div>
          ))
        ) : (
          <div className={styles.placeholder}>{placeholder}</div>
        )}
      </div>
    </Form.Group>
  );
};

export default FileUploader;
