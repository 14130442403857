import { call, put } from 'redux-saga/effects';
import {
  getRolesFailure,
  getRolesSuccess,
  assignRolesSuccess,
  assignRolesFailure,
} from './reducer';
import { generateErrorMessage } from 'utils';
import { PayloadAction } from '@reduxjs/toolkit';
import { getCustomerRequest } from '../customers/reducer';
import api from 'api/middleware';
import config from 'api/config';

export function* getRoles() {
  try {
    const { data } = yield call(api, config.discordRoles);
    yield put(getRolesSuccess(data));
  } catch (e) {
    yield put(getRolesFailure(generateErrorMessage(e)));
  }
}

export function* assignRolesForCustomer({
  payload,
}: PayloadAction<{ customerId: number; roleIds: string[] }>) {
  try {
    yield call(api.post, config.discordRolesAssign, payload);
    yield put(
      getCustomerRequest({
        id: payload.customerId,
        join: ['orders', 'discordUser'],
      }),
    );
    yield put(assignRolesSuccess());
  } catch (e) {
    yield put(assignRolesFailure(generateErrorMessage(e)));
  }
}
