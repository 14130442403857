import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ApiFreezeContract,
  ApiGetContracts,
  ApiGetTokens,
  ApiPrepareFreeze,
  IBlockChain,
  IBlockchain,
  ISmartContract,
  ISmartContractApi,
  ISmartContractTemplate,
  ISmartContractTemplateApi,
  IToken,
  ITokenApi,
  OrdersPatchApi,
} from './types';

import { ethers } from 'ethers';
import { IOrder } from '../nft/types';

const initialState: IBlockchain = {
  error: '',
  loading: false,
  fetching: false,
  blockchain: null,
  blockchains: [],
  smartContracts: [],
  smartContractsAmount: 0,
  smartContract: null,
  templates: [],
  template: null,
  tokens: [],
  tokensAmount: 0,
};

const slice = createSlice({
  name: 'blockchain',
  initialState,
  reducers: {
    getBlockChainRequest: (state, i: PayloadAction<string>) => {
      state.loading = true;
    },
    getBlockChainSuccess: (state, { payload }: PayloadAction<IBlockChain>) => {
      state.loading = false;
      state.blockchain = payload;
    },
    getBlockChainsRequest: (state) => {
      state.loading = true;
    },
    getBlockChainsSuccess: (
      state,
      { payload }: PayloadAction<IBlockChain[]>,
    ) => {
      state.loading = false;
      state.blockchains = payload;
    },

    getSCTemplateRequest: (state, i: PayloadAction<number>) => {
      state.loading = true;
    },
    getSCTemplateSuccess: (
      state,
      { payload }: PayloadAction<ISmartContractTemplate>,
    ) => {
      state.template = payload;
      state.loading = false;
    },
    getSCTemplateFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    createSCTemplateRequest: (
      state,
      payload: PayloadAction<ISmartContractTemplateApi>,
    ) => {
      state.loading = true;
    },
    updateSCTemplateRequest: (
      state,
      payload: PayloadAction<ISmartContractTemplateApi>,
    ) => {
      state.loading = true;
    },
    deleteSCTemplateRequest: (state, payload: PayloadAction<number>) => state,
    getSmartContractsRequest: (
      state,
      { payload }: PayloadAction<ApiGetContracts>,
    ) => {
      state.loading = !payload.more;
    },
    getSmartContractsSuccess: (
      state,
      { payload }: PayloadAction<{ list: ISmartContract[]; amount: number }>,
    ) => {
      state.loading = false;
      state.smartContracts = payload.list;
      state.smartContractsAmount = payload.amount;
    },
    getSmartContractRequest: (state, i: PayloadAction<number>) => {
      state.loading = true;
    },
    createSmartContractRequest: (
      state,
      i: PayloadAction<ISmartContractApi>,
    ) => {
      state.loading = true;
    },
    updateSmartContractRequest: (
      state,
      i: PayloadAction<ISmartContractApi>,
    ) => {
      state.loading = true;
    },
    getSmartContractSuccess: (
      state,
      { payload }: PayloadAction<ISmartContract | null>,
    ) => {
      state.loading = false;
      state.smartContract = payload;
    },
    getTokensRequest: (state, i: PayloadAction<ApiGetTokens>) => {
      state.loading = true;
    },
    getTokensSuccess: (
      state,
      { payload }: PayloadAction<{ list: IToken[]; amount: number }>,
    ) => {
      state.loading = false;
      state.tokens = payload.list;
      state.tokensAmount = payload.amount;
    },
    createTokenRequest: (state, { payload }: PayloadAction<ITokenApi>) => {
      state.loading = true;
    },
    blockchainCreateSmartContract: (state, { payload }: PayloadAction<any>) => {
      state.loading = true;
    },
    blockchainCreateToken: (state, { payload }: PayloadAction<any>) => {
      state.loading = true;
    },
    createTokenFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    fulfillOrderRequest: (state, i: PayloadAction<OrdersPatchApi>) => {
      state.loading = true;
    },
    blockchainFulfillOrdersRequest: (
      state,
      { payload }: PayloadAction<any>,
    ) => {
      state.loading = true;
    },
    fulfillOrderSuccess: (state) => {
      state.loading = false;
    },
    PrepareFreezeRequest: (state, i: PayloadAction<ApiPrepareFreeze>) => {
      state.fetching = true;
    },
    PrepareFreezeSuccess: (state) => {
      state.fetching = false;
    },
    prepareFreezeFailure: (state, { payload }: PayloadAction<string>) => {
      state.fetching = false;
      state.error = payload;
    },
    FreezeContractRequest: (state, i: PayloadAction<ApiFreezeContract>) => {
      state.fetching = true;
    },
  },
});

export const {
  getBlockChainRequest,
  getBlockChainSuccess,
  getBlockChainsRequest,
  getBlockChainsSuccess,
  getSCTemplateRequest,
  getSCTemplateFailure,
  getSCTemplateSuccess,
  createSCTemplateRequest,
  updateSCTemplateRequest,
  getSmartContractsRequest,
  getSmartContractsSuccess,
  getSmartContractRequest,
  createSmartContractRequest,
  getSmartContractSuccess,
  updateSmartContractRequest,
  deleteSCTemplateRequest,
  getTokensRequest,
  getTokensSuccess,
  createTokenRequest,
  blockchainCreateSmartContract,
  blockchainCreateToken,
  createTokenFailure,
  fulfillOrderRequest,
  fulfillOrderSuccess,
  blockchainFulfillOrdersRequest,
  PrepareFreezeRequest,
  PrepareFreezeSuccess,
  prepareFreezeFailure,
  FreezeContractRequest,
} = slice.actions;
export const { reducer } = slice;
