import { memo } from 'react';
import { Modal } from 'react-bootstrap';
import { IModalProps } from './types';

const ModalComponent = memo(
  ({ show, toggle, title, size, children }: IModalProps) => {
    return (
      <Modal show={show} onHide={toggle} size={size}>
        <Modal.Header onHide={toggle} closeButton>
          <h4 className="modal-title">{title}</h4>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    );
  },
);

export default ModalComponent;
