import { Config, ConfigType, IUpdateConfigRequest } from './types';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';
import {
  copyPasteConfigFailure,
  copyPasteConfigSuccess,
  createConfigFailure,
  createConfigSuccess,
  deleteManyConfigsFailure,
  deleteManyConfigsSuccess,
  encryptDataFailure,
  encryptDataSuccess,
  getConfigByIdOrNameFailure,
  getConfigByIdOrNameRequest,
  getConfigByIdOrNameSuccess,
  getConfigsFailure,
  getConfigsRequest,
  getConfigsSuccess,
  getConfigTypeFailure,
  getConfigTypesFailure,
  getConfigTypesSuccess,
  getConfigTypeSuccess,
  updateConfigFailure,
  uploadConfigFilesFailure,
  uploadConfigFilesSuccess,
} from './reducer';
import { generateErrorMessage } from 'utils';
import api from 'api/middleware';
import config from 'api/config';
import { toast } from 'react-toastify';
import { toastSettings } from 'constants/global';
import { selectConfigs, selectConfigType } from './selectors';
import { history } from 'navigation';
import routes from 'navigation/paths';
import { generatePath } from 'react-router';

export function* getConfigTypeSaga({
  payload,
}: PayloadAction<{ name: string }>) {
  try {
    const { data }: { data: ConfigType } = yield call(
      api,
      config.configType(payload.name),
    );

    yield put(getConfigTypeSuccess(data));
  } catch (e) {
    yield put(getConfigTypeFailure(generateErrorMessage(e)));
  }
}

export function* getConfigTypesSaga() {
  try {
    const { data }: { data: ConfigType[] } = yield call(
      api,
      config.configTypes,
    );

    yield put(getConfigTypesSuccess(data));
  } catch (e) {
    yield put(getConfigTypesFailure(generateErrorMessage(e)));
  }
}

export function* getConfigsSaga({ payload }: PayloadAction<{ type: string }>) {
  try {
    const { data: configs }: { data: Config[] } = yield call(
      api,
      config.configs,
      { params: { typeName: payload.type } },
    );

    yield put(getConfigsSuccess(configs));
  } catch (e) {
    yield put(getConfigsFailure(generateErrorMessage(e)));
  }
}

export function* getConfigByIdOrNameSaga({
  payload,
}: PayloadAction<string | number>) {
  try {
    const { data }: { data: Config } = yield call(
      api,
      config.configsByIdOrName(payload),
    );

    yield put(getConfigByIdOrNameSuccess(data));
  } catch (e) {
    yield put(getConfigByIdOrNameFailure(generateErrorMessage(e)));
  }
}

export function* updateConfigSaga({
  payload: { configId, body },
}: PayloadAction<{ configId: number; body: IUpdateConfigRequest }>) {
  try {
    const { json, ...data } = body;

    const jsonIsEmpty = !Object.keys(json || {}).length;

    const { data: updatedConfig }: { data: Config } = yield call(
      api.patch,
      config.configsByIdOrName(configId),
      {
        ...data,
        ...(!jsonIsEmpty && { json }),
      },
    );
    yield put(getConfigsRequest({ type: updatedConfig.type.name }));
    history.push(
      generatePath(routes.configType, {
        configTypeName: updatedConfig.type.name,
      }),
    );
  } catch (e) {
    yield put(updateConfigFailure(generateErrorMessage(e)));
  }
}

export function* deleteManyConfigsSaga({ payload }: PayloadAction<number[]>) {
  try {
    yield call(api.delete, config.configs, { data: { ids: payload } });
    yield put(deleteManyConfigsSuccess());
    const fetchedConfigs: Config[] = yield select(selectConfigs);
    yield put(getConfigsRequest({ type: fetchedConfigs[0].type.name }));
  } catch (e) {
    yield put(deleteManyConfigsFailure(generateErrorMessage(e)));
  }
}

export function* createConfigSaga({
  payload: { name, typeId, json = {} },
}: PayloadAction<{
  name: string;
  typeId: number;
  json?: Record<string, any>;
}>) {
  try {
    yield call(api.post, config.configs, {
      name,
      typeId,
      json,
    });
    yield put(createConfigSuccess());
    const configType: ConfigType = yield select(selectConfigType);
    yield put(getConfigsRequest({ type: configType.name }));
  } catch (e) {
    yield put(createConfigFailure(generateErrorMessage(e)));
  }
}

export function* encryptDataSaga({
  payload: { data, cb },
}: PayloadAction<{ data: string; cb?: (hash: string) => void }>) {
  try {
    const {
      data: { hash },
    } = yield call(api.post, config.encrypt, {
      data,
    });
    yield put(encryptDataSuccess());
    if (cb) cb(hash);
  } catch (e) {
    yield put(encryptDataFailure(generateErrorMessage(e)));
  }
}

export function* copyPasteConfigSaga({
  payload: { toId, fromId },
}: PayloadAction<{ toId: number; fromId: number }>) {
  try {
    yield call(api.patch, config.copyPasteConfig(fromId, toId));
    yield put(copyPasteConfigSuccess());
    yield toast.success('Copying was successfully!', toastSettings);
    yield put(getConfigByIdOrNameRequest(toId));
  } catch (e) {
    yield put(copyPasteConfigFailure(generateErrorMessage(e)));
  }
}

export function* uploadConfigFilesSaga({
  payload,
}: PayloadAction<{ configId: number; type: string; files: File[] }>) {
  try {
    const fd = new FormData();
    fd.append('type', payload.type);
    payload.files.forEach((file) => fd.append('files', file));

    const { data }: { data: { urls: string[] } } = yield call(
      api.post,
      config.configsFiles(String(payload.configId)),
      fd,
    );

    yield put(uploadConfigFilesSuccess(data.urls));
  } catch (e) {
    yield put(uploadConfigFilesFailure(generateErrorMessage(e)));
  }
}
